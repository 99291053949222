<script setup>
import AddShipTable from './AddShipTable.vue'
import AddShipSelectedShip from './AddShipSelectedShip.vue'
import { computed } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'
import ShipChart from '../chart/ShipChart.vue'

import { useShipStore } from '../../stores/ship'
import { useShipApiStore } from '../../stores/shipApi'

const shipStore = useShipStore()
const shipApiStore = useShipApiStore()

const { currentItem } = storeToRefs(useShipApiStore())
const { addLoading } = storeToRefs(useShipStore())

const emit = defineEmits(['setDialog'])
const props = defineProps({
  dialog: { type: Boolean, required: true }
})

let dialogValue = computed({
  get() {
    return props.dialog
  },
  set(value) {
    emit('setDialog', value)
  }
})

async function saveShip() {
  await shipStore.addShip()
  emit('setDialog', false)
  shipApiStore.removeSelectedItems()
}
</script>

<template>
  <v-dialog
    v-model="dialogValue"
    style="background-color: white"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card class="fill-height" rounded="0">
      <v-toolbar dark color="primary" density="compact">
        <v-toolbar-title>Choose vessel</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters class="fill-dialog">
        <v-col class="fill-height pa-0" cols="7">
          <AddShipTable></AddShipTable>
        </v-col>
        <v-col class="border-left-grey fill-height" cols="5">
          <div class="d-flex fill-height flex-column pl-4">
            <v-lazy class="d-flex flex-column" style="height: 80vh">
              <!--            TODO still required a 80vh to let the overflow and the chart take the correct height-->
              <ShipChart v-if="currentItem" :ship="currentItem" chart-ref="chartRef"></ShipChart>
              <div v-else class="d-flex align-center justify-center fill-dialog">
                <div class="text-center">No data available</div>
              </div>
            </v-lazy>
            <v-lazy class="d-flex flex-column overflow-y-auto">
              <AddShipSelectedShip></AddShipSelectedShip>
            </v-lazy>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          color="primary"
          :disabled="addLoading"
          class="text-none"
          @click="dialogValue = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="addLoading"
          variant="text"
          color="primary"
          @click="saveShip()"
          class="text-none"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.fill-dialog {
  height: calc(100% - 102px);
}
</style>
