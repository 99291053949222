<script setup>
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { useCalculationStore } from '@/stores/calculation'
import { useRoute } from 'vue-router/dist/vue-router'
import { computed } from 'vue'
import { RHDHVToolbar } from '@rhdhv/vue-component-library'

const { currentItem: currentProject } = storeToRefs(useProjectStore())
const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())

const route = useRoute()

const currentRouteName = computed(() => route.name)
</script>

<template>
  <RHDHVToolbar>
    <template v-slot:toolbar-title>
      <div class="d-flex justify-center align-center">
        <v-toolbar-title class="app-title ml-2">MOOR<sup>2</sup></v-toolbar-title>
      </div>
    </template>
    <template v-slot:breadcrumbs-items>
      <v-breadcrumbs-item>
        <router-link
          class="text-white toolbar-text text-decoration-none"
          :to="{ name: 'project-index' }"
        >
          Projects
        </router-link>
      </v-breadcrumbs-item>

      <template v-if="currentRouteName !== 'project-index' && currentProject.id">
        <v-breadcrumbs-divider />
        <v-breadcrumbs-item>
          <router-link
            class="text-white toolbar-text text-decoration-none"
            :to="{ name: 'calculation-index', id: currentProject.id }"
          >
            {{ currentProject.name }}
          </router-link>
        </v-breadcrumbs-item>

        <template v-if="currentCalculation.id && currentRouteName !== 'calculation-index'">
          <v-breadcrumbs-divider />
          <v-breadcrumbs class="text-white toolbar-text">
            {{ currentCalculation.name }}
          </v-breadcrumbs>
          <v-breadcrumbs-divider />
          <v-breadcrumbs class="text-white toolbar-text"> Details </v-breadcrumbs>
        </template>
        <template v-else>
          <v-breadcrumbs-divider />
          <v-breadcrumbs class="text-white toolbar-text"> Calculations </v-breadcrumbs>
        </template>
      </template>
    </template>
  </RHDHVToolbar>
</template>
