<script setup>
import TextField from '@/components/form/TextField.vue'

import { useFairleadStore } from '@/stores/fairlead'
import { useMooringPointStore } from '@/stores/mooring_point'
import { computed } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'
import { useShipStore } from '@/stores/ship'

const store = useFairleadStore()

const { items: mooringPointItems } = storeToRefs(useMooringPointStore())
const { items: shipItems } = storeToRefs(useShipStore())

import { FAIRLEAD_LABEL, WINCH_LABEL, MOORING_POINT_LABEL } from '@/components/chart/chartLabels'
import useEventsBus from '@/bus/eventBus'
import { useAuthorizationStore } from '@/stores/authorization'
import ChipSelection from '@/components/fairlead/ChipSelection.vue'

const { emit } = useEventsBus()

const props = defineProps({
  item: { type: Object, required: true },
  index: { type: Number, required: true },
  winchItems: { type: Array, required: true },
  fairleadItems: { type: Array, required: true }
})

const mooringPoint = computed(() => {
  return (
    mooringPointItems.value.find(
      (mooringPointItem) => props.item.mooring_point === mooringPointItem.id
    ) || null
  )
})

const winch = computed(() => {
  return props.winchItems.find((winchItem) => props.item.winch === winchItem.id) || null
})

const ship = computed(() => {
  return shipItems.value.find((item) => props.item.ship === item.id)
})

const xWithEccentricity = computed(() => {
  return (props.item.x + ship.value?.eccentricity).toFixed(1)
})

async function setMooringPoint(item) {
  if (props.item.mooring_point === item.id) {
    store.setParameter(props.item, 'mooring_point', null)
    store.setParameter(props.item, 'horizontal_angle', NaN)
    store.setParameter(props.item, 'horizontal_color', null)
    store.setParameter(props.item, 'vertical_angle', NaN)
    store.setParameter(props.item, 'vertical_color', null)
  } else {
    store.setParameter(props.item, 'mooring_point', item.id)
  }
  store.addEditedItem(props.item)
  await store.update(props.item)
}

async function setWinch(item) {
  if (props.item.winch === item.id) {
    store.setParameter(props.item, 'mooring_point', null)
    store.setParameter(props.item, 'winch', null)
    store.setParameter(props.item, 'line_type', null)
    store.setParameter(props.item, 'horizontal_angle', NaN)
    store.setParameter(props.item, 'horizontal_color', null)
    store.setParameter(props.item, 'vertical_angle', NaN)
    store.setParameter(props.item, 'vertical_color', null)
  } else {
    if (!props.item.line_type) {
      store.setParameter(props.item, 'line_type', lineTypeOptions.value[0])
    }
    store.setParameter(props.item, 'winch', item.id)
  }
  store.addEditedItem(props.item)
  await store.update(props.item)
}

async function setFairlead(item) {
  store.removeEditedItem(props.item)
  const lineType = props.item.line_type
  const winch = props.item.winch
  const mooringPoint = props.item.mooring_point
  store.setParameter(props.item, 'mooring_point', null)
  store.setParameter(props.item, 'winch', null)
  store.setParameter(props.item, 'line_type', null)
  store.setParameter(props.item, 'color', null)
  store.setParameter(props.item, 'horizontal_angle', NaN)
  store.setParameter(props.item, 'horizontal_color', null)
  store.setParameter(props.item, 'vertical_angle', NaN)
  store.setParameter(props.item, 'vertical_color', null)
  const sourceFairlead = props.item
  if (props.item.id !== item.id) {
    const targetFairlead = store.items.find((fairlead) => fairlead.id === item.id)

    store.swapItems(props.item, targetFairlead)
    store.setParameter(targetFairlead, 'mooring_point', mooringPoint)
    store.setParameter(targetFairlead, 'winch', winch)
    store.setParameter(targetFairlead, 'line_type', lineType)
    store.addEditedItem(targetFairlead)
    await store.update(targetFairlead)
  }
  await store.update(sourceFairlead)
}

const authorizationStore = useAuthorizationStore()

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_fairlead'
  })
)

const lineType = computed({
  get() {
    return props.item.line_type
  },
  set(value) {
    store.setParameter(props.item, 'line_type', value)
    store.addEditedItem(props.item)
    store.update(props.item)
  }
})

const lineTypeOptions = computed(() => {
  return store.fields['line_type'].choices?.map((choice) => choice.display_name) || []
})

const { selectedItems } = storeToRefs(store)

const isSelected = computed({
  get() {
    return !!selectedItems.value.includes(props.item)
  },
  set() {
    store.toggleSelectedItem(props.item)
  }
})
</script>

<template>
  <tr
    :class="{
      'selected-item': isSelected
    }"
  >
    <td class="px-1 text-center">
      <v-checkbox
        @click.stop=""
        color="primary"
        density="compact"
        hide-details
        v-model="isSelected"
      ></v-checkbox>
    </td>
    <td class="px-0 text-center">{{ index + 1 }}</td>

    <td style="width: 1%; font-size: 12px" class="pr-0">
      <ChipSelection
        :can-edit="canEdit"
        :item="item"
        :items="fairleadItems"
        colorField="winch_color"
        @set="setFairlead"
        @right-click-event="
          (e) =>
            emit('chartEvent', {
              id: e.id,
              label: FAIRLEAD_LABEL + ship.id
            })
        "
      >
        <template v-slot:selection="{ item }"> F{{ item?.number ?? '0' }} </template>
        <template v-slot:option="{ item }"> F{{ item?.number }} </template>
      </ChipSelection>
    </td>
    <td style="width: 1%" class="pr-0">
      <ChipSelection
        :can-edit="canEdit"
        :item="winch"
        :items="winchItems"
        @set="setWinch"
        @right-click-event="
          (e) =>
            emit('chartEvent', {
              id: e.id,
              label: WINCH_LABEL + ship.id
            })
        "
      >
        <template v-slot:selection="{ item }"> W{{ item?.number ?? 0 }} </template>
        <template v-slot:option="{ item }"> W{{ item?.number }} </template>
      </ChipSelection>
    </td>
    <td class="pr-0">
      <ChipSelection
        :can-edit="canEdit"
        :item="mooringPoint"
        :items="mooringPointItems"
        @set="setMooringPoint"
        @right-click-event="
          (e) =>
            emit('chartEvent', {
              id: e.id,
              label: MOORING_POINT_LABEL
            })
        "
      >
        <template v-slot:selection="{ item }">
          {{ item?.name ?? 'MP0' }}
        </template>
        <template v-slot:option="{ item }">
          {{ item?.name }}
        </template>
      </ChipSelection>
    </td>
    <td class="pr-0">
      <v-select
        v-if="canEdit"
        class="line-type-select"
        v-model="lineType"
        hide-details
        density="compact"
        variant="plain"
        :items="lineTypeOptions"
      ></v-select>
    </td>

    <td class="pr-0 border-left-grey">{{ xWithEccentricity }}</td>
    <td class="pr-0">{{ item.y?.toFixed(1) }}</td>
    <td class="border-right-grey">{{ item.calculated_z?.toFixed(1) }}</td>
    <td class="border-right-grey pr-1 pl-1">
      <TextField
        :store="store"
        shrink
        :field="store.fields['minimum_horizontal_angle']"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        no-help-text
        center
        font-size="14px"
        class="text-field"
        @update="store.update(item)"
      ></TextField>
    </td>
    <td
      class="border-right-grey"
      :style="`border-bottom-width: ${
        mooringPoint && item.line_length_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.line_length_color : null
      };`"
    >
      {{ item.line_length?.toFixed(2) }}
    </td>
    <td
      class="pr-0"
      :style="`border-bottom-width: ${
        mooringPoint && item.horizontal_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.horizontal_color : null
      };`"
    >
      <div v-if="mooringPoint">
        {{ Math.round(item.horizontal_angle * 100) / 100 }}
      </div>
    </td>
    <td
      class="pr-0"
      :style="`border-bottom-width: ${
        mooringPoint && item.vertical_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.vertical_color : null
      };`"
    >
      <div v-if="mooringPoint">
        {{ Math.round(item.vertical_angle * 100) / 100 }}
      </div>
    </td>
  </tr>
</template>

<style scoped>
.td {
  text-align: center;
}

.text-field :deep(.v-input) {
  width: 100% !important;
}

.line-type-select :deep(.v-field__input) {
  padding-top: 4px !important;
}
</style>
