<script setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router/dist/vue-router'

import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import UserManagementIndexTile from '@/components/user-management/UserManagementIndexTile.vue'

import { useProjectUserStore } from '@/stores/project_user'
import { AddUserDialog, UserManagementTable } from '@rhdhv/vue-user-management'
import { useAuthorizationStore } from '@/stores/authorization'
import { useProjectStore } from '@/stores/project'
import { AppBackground } from '@rhdhv/vue-component-library'

const store = useProjectUserStore()
const projectStore = useProjectStore()

let route = useRoute()
const projectUserStore = useProjectUserStore()

const authorizationStore = useAuthorizationStore()

const canEdit = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_projectuser'
  })
})

onMounted(async () => {
  await store.fetchItems({ project: route.params.id })
})
</script>

<template>
  <AppBackground class="pa-4">
    <UserManagementIndexTile />
    <UserManagementTable
      :delete-dialog="DeleteDialog"
      :can-edit="!!canEdit"
      auth-role="ProjectUser"
      :auth-model-user-store="projectUserStore"
      auth-model-key="project"
    />
    <AddUserDialog
      :auth-model-user-store="projectUserStore"
      :auth-model-store="projectStore"
      auth-model-key="project"
    />
  </AppBackground>
</template>
