<script setup>
import { storeToRefs } from 'pinia/dist/pinia'
import { useCalculationStore } from '@/stores/calculation'
import { useProjectStore } from '@/stores/project'
import RHDHVButton from '@/components/common/RHDHVButton.vue'

const { currentItem: currentProject } = storeToRefs(useProjectStore())

const store = useCalculationStore()
</script>

<template>
  <v-card elevation="0" variant="flat">
    <v-card-title>
      Calculations

      <RHDHVButton
        allowed="add_calculation"
        auth-source="ProjectUser"
        prepend-icon="mdi-plus"
        class="ml-2 text-none"
        elevation="0"
        @click="store.toggleCreateUpdateDialog({ project: currentProject.id })"
      >
        Add
      </RHDHVButton>
    </v-card-title>
    <v-card-subtitle>
      Manage calculations in this project, please select an existing calculation below or create a
      new calculation via the
      <v-btn
        class="text-none"
        size="x-small"
        prepend-icon="mdi-plus"
        elevation="0"
        :ripple="false"
        style="cursor: default"
      >
        Add
      </v-btn>
      button.
    </v-card-subtitle>
  </v-card>
</template>

<style scoped>
.v-card-title {
  display: flex;
  align-items: center;
}
</style>
