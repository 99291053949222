<script setup>
import HelpTextTooltip from '../form/HelpTextTooltip.vue'

import { storeToRefs } from 'pinia/dist/pinia'
import { ref, watch } from 'vue'

import { useOptimisationStore } from '@/stores/optimisation'
import { useCalculationStore } from '@/stores/calculation'
import { useMooringPointStore } from '@/stores/mooring_point'
import { useShipStore } from '@/stores/ship'
import { useFairleadStore } from '@/stores/fairlead'
import { useWinchStore } from '@/stores/winch'
import { useShipContourStore } from '@/stores/ship_contour'
import { useShipApiStore } from '@/stores/shipApi'
import { ISLAND } from '@/components/calculation/calculationParameterTypes'
import RHDHVButton from '@/components/common/RHDHVButton.vue'
import MooringPointTab from '@/components/mooring-points/MooringPointTab.vue'
import ShipTab from '@/components/ship/ShipTab.vue'

const shipStore = useShipStore()
const optimisationStore = useOptimisationStore()
const mooringPointStore = useMooringPointStore()
const fairleadStore = useFairleadStore()
const winchStore = useWinchStore()
const shipContourStore = useShipContourStore()
const shipApiStore = useShipApiStore()

const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())
const { loading: optimiseLoading, generateLoading } = storeToRefs(optimisationStore)
const { loading: mooringPointLoading } = storeToRefs(mooringPointStore)
const { loading: shipLoading } = storeToRefs(shipStore)

const tab = ref('vessel')

let mooringPointHelpText = ref(
  'Here the coordinates (X, Y, Z) of all mooring points are shown; X and Y are relative to the ‘earth-fixed coordinate system (shown on the left) and Z is relative to CD'
)
let designVesselHelpText = ref(
  'Here you can define the design vessels, from certain vessel classes/ranges with ‘typical’ vessel particulars, which can be overwritten manually '
)

watch(currentCalculation, async (currentState) => {
  if (currentState.id) {
    await mooringPointStore.fetchOptions()
    await fairleadStore.fetchOptions()
    await shipStore.fetchOptions()
    await shipApiStore.fetchOptions()
    await optimisationStore.fetchItem(currentState.optimisation)
    await shipStore.fetchItems({ calculation: currentState.id })
    await fairleadStore.fetchItems({ ship__calculation: currentState.id })
    await mooringPointStore.fetchItems({ calculation: currentState.id })
    await winchStore.fetchItems({ ship__calculation: currentState.id })
    await shipContourStore.fetchItems({ ship__calculation: currentState.id })
  }
})
</script>

<template>
  <div class="d-flex fill-height flex-column">
    <v-card class="pa-0" height="92%" rounded="0" width="100%" variant="text">
      <v-tabs v-model="tab" fixed-tabs grow style="z-index: 1" color="primary">
        <v-tab rounded="0" value="vessel">
          <span class="mr-3"> Design vessels </span>
          <v-progress-circular
            v-if="shipLoading"
            size="x-small"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <HelpTextTooltip v-else :text="designVesselHelpText" color="primary"></HelpTextTooltip>
        </v-tab>
        <v-tab rounded="0" value="mooring">
          <span class="mr-3"> Mooring points </span>
          <v-progress-circular
            v-if="mooringPointLoading"
            size="x-small"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <HelpTextTooltip v-else :text="mooringPointHelpText" color="primary"></HelpTextTooltip>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab" class="d-flex flex-column fill-height">
        <v-window-item value="vessel">
          <ShipTab></ShipTab>
        </v-window-item>
        <v-window-item value="mooring">
          <MooringPointTab></MooringPointTab>
        </v-window-item>
      </v-window>
    </v-card>
    <v-card class="center d-flex flex-column flex-grow-1" variant="flat" rounded="0">
      <RHDHVButton
        v-if="currentCalculation.berth_type === ISLAND"
        item-class="ml-0"
        :loading="optimiseLoading"
        width="50%"
        allowed="add_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        elevation="0"
        size="large"
        prepend-icon="mdi-refresh"
        @click="optimisationStore.optimise()"
      >
        Optimise
      </RHDHVButton>

      <RHDHVButton
        v-else
        :disabled="generateLoading"
        :loading="optimiseLoading"
        item-class="ml-0"
        width="50%"
        allowed="change_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        size="large"
        elevation="0"
        append-icon="mdi-refresh"
        @click="optimisationStore.linkToFairleads()"
      >
        Link to fairleads
      </RHDHVButton>
    </v-card>
  </div>
</template>
<style scoped>
.v-window-item {
  height: calc(100vh - 90px - 48px - 84px); /* All toolbars  */
  overflow-y: auto;
}

:deep(.v-tab) {
  max-width: unset !important;
}
</style>
