<script setup>
const props = defineProps({
  modelValue: { type: Boolean, required: true },
  loading: { type: Boolean, required: true }
})

import { computed } from 'vue'

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

const emit = defineEmits(['update:modelValue', 'exportExcel'])
</script>

<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-card-title>Export Excel</v-card-title>
      <v-card-text> Export excel file for this calculation </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" :loading="loading" @click="emit('exportExcel')">Export</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
