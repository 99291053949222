<script setup>
import { storeToRefs } from 'pinia'
import { useCalculationStore } from '@/stores/calculation'
import CalculationTableRow from './CalculationTableRow.vue'
import NoDataBar from '@/components/calculation-index/NoDataBar.vue'

const { loading } = storeToRefs(useCalculationStore())
const store = useCalculationStore()

const props = defineProps({
  filteredItems: { type: Array, required: true }
})

const HEADER = [
  { title: '', value: '', width: '3%', align: 'center' },
  { title: '#', value: 'order', width: '5%', align: 'center' },
  { title: 'Name', value: 'name', width: '20%' },
  { title: 'Berth type', value: 'berth_type', width: '8%' },
  { title: 'Ship type', value: 'ship_type', width: '8%' },
  { title: 'Vessel mooring side', value: 'vessel_mooring_side', width: '12%' },
  { title: 'Description', value: 'description', width: '40%' },
  { title: 'Edit', value: '', width: '4%', align: 'center' }
]
</script>
<template>
  <v-data-table
    class="mt-4 d-flex flex-column overflow-y-auto"
    hover
    density="comfortable"
    fixed-header
    :items="filteredItems"
    :headers="HEADER"
    height="fill-height"
    :loading="loading"
    color="primary"
  >
    <template v-slot:item="{ item, index }">
      <CalculationTableRow
        :key="index"
        :order="index + 1"
        :item="item"
        @edit-item="store.toggleCreateUpdateDialog(item)"
      />
    </template>
    <template v-slot:no-data>
      <NoDataBar text="No data, please add a calculation to continue." />
    </template>
  </v-data-table>
</template>
