<script setup>
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'
import router from '@/router'
import { useRoute } from 'vue-router/dist/vue-router'

import { useCalculationStore } from '@/stores/calculation'
import { useProjectStore } from '@/stores/project'
import { useAuthorizationStore } from '@/stores/authorization'

import ProjectTitle from '@/components/calculation/ProjectTitle.vue'
import CalculationForm from '@/components/calculation/CalculationForm.vue'
import SpeckleDrawer from '@/components/speckle/SpeckleDrawer.vue'

const route = useRoute()

const store = useCalculationStore()
const projectStore = useProjectStore()
const authorizationStore = useAuthorizationStore()

const { drawer } = storeToRefs(projectStore)
const { currentItem, items, loading } = storeToRefs(useCalculationStore())

const rail = ref(true)

const calculationInputDrawer = computed({
  get: () => drawer.value,
  set: (value) => {
    projectStore.toggleDrawer(value)
  }
})

const currentItemLoaded = computed({
  get() {
    return !_.isEmpty(currentItem.value)
  }
})

function navigateToCalculationPage() {
  router.push({ name: 'project-detail', params: { id: route.params.id } })
}

function setDrawer() {
  projectStore.toggleDrawer()
  projectStore.toggleSpeckleDrawer(false)
}

function setExportDrawer() {
  projectStore.toggleDrawer(false)
  projectStore.toggleSpeckleDrawer()
}

onMounted(async () => {
  await projectStore.fetchItem(route.params.id)
  await store.fetchOptions()
  await store.fetchItems({ project: route.params.id })
  const item = items.value.find((item) => item.id === parseInt(route.params.calculationId))
  store.setCurrentItem(item)
})
</script>

<template>
  <v-navigation-drawer v-model="rail" rail>
    <v-list>
      <v-list-item prepend-icon="mdi-file-edit" @click.stop="setDrawer()"></v-list-item>
      <v-list-item @click.stop="setExportDrawer()">
        <v-icon size="x-large" icon="custom:speckleLogoIcon"></v-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-navigation-drawer
    v-model="calculationInputDrawer"
    scrim="rgba(0,0,0,0)"
    temporary
    elevation="2"
    width="500"
  >
    <v-list class="pb-0">
      <v-list-item @click.stop="setDrawer()">
        <template #title>
          <ProjectTitle />
        </template>
        <template #append>
          <v-btn
            variant="text"
            :icon="!calculationInputDrawer ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click.stop="setDrawer;"
          ></v-btn>
        </template>
      </v-list-item>

      <v-progress-linear v-if="loading || !currentItemLoaded" indeterminate color="primary">
      </v-progress-linear>

      <template v-else>
        <v-divider></v-divider>
        <v-tooltip>
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-keyboard-return"
              @click="navigateToCalculationPage()"
            >
              <v-list-item-title class="tex-h6">
                {{ currentItem.name }}
              </v-list-item-title>
            </v-list-item>
          </template>
          Back to calculations
        </v-tooltip>
        <v-divider></v-divider>
        <v-list
          class="pa-0 ma-0"
          :disabled="
            !authorizationStore.hasServicePermission({
              authSource: 'ProjectUser',
              allowed: 'change_calculation'
            })
          "
        >
          <template v-if="store.fields && currentItemLoaded">
            <CalculationForm></CalculationForm>
          </template>
        </v-list>
      </template>
    </v-list>
  </v-navigation-drawer>
  <SpeckleDrawer></SpeckleDrawer>
</template>
