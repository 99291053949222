const baseURL = import.meta.env.VITE_APP_SHIP_API_BASE_URL

const API = {
  TOKEN: {
    INDEX: `${baseURL}/api/token`,
    REFRESH: `${baseURL}/api/token/refresh/`
  },
  SHIPS: {
    INDEX: `${baseURL}ships/`
  }
}

export default API
