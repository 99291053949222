<script setup>
import ToolBar from '@/components/app/ToolBar.vue'
import { useAuthorizationStore } from '@/stores/authorization'
import { storeToRefs } from 'pinia'
import { inject, onMounted, ref, watch } from 'vue'
import {
  RHDHVHeaderLogo,
  RHDHVHeaderMenu,
  RHDHVLoader,
  DocumentationButton
} from '@rhdhv/vue-component-library'
import useEventsBus from '@/bus/eventBus'
import { useRouter } from 'vue-router'

const authorizationStore = useAuthorizationStore()
const router = useRouter()

const { items: authorizations } = storeToRefs(authorizationStore)

const authStore = inject('useAuthStore')

const { user } = storeToRefs(authStore)

const { bus } = useEventsBus()

const loading = ref(false)

watch(
  () => bus.value.get('loading'),
  (val) => {
    loading.value = val[0]
  }
)

onMounted(async () => {
  if (authorizations.value.length === 0) {
    await authorizationStore.fetchItems()
  }
  authorizationStore.setCurrentRole([user.value.role])
})

const goToProjectIndex = () => {
  router.push({ name: 'project-index' })
}

async function logout() {
  await authStore.logout()
  await authStore.$patch({
    user: null
  })
  await router.push({ name: 'auth-login' })
}
</script>

<template>
  <v-app-bar fixed app color="white" dark class="pa-0" height="90" extension-height="40">
    <RHDHVHeaderLogo
      @logo-click="goToProjectIndex"
      class="pl-3"
      height="90"
      width="150"
      image-src="/rhdhv-logo.jpg"
    />
    <v-spacer></v-spacer>
    <RHDHVLoader :loading="loading" />
    <DocumentationButton />
    <RHDHVHeaderMenu v-if="user" :user="user" @logout="logout()" color="#a9a9a9"></RHDHVHeaderMenu>
    <template #extension>
      <ToolBar />
    </template>
  </v-app-bar>
</template>
