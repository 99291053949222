<script setup>
import { useShipStore } from '@/stores/ship'
import { computed } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'

const authorizationStore = useAuthorizationStore()
const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_calculation'
  })
)
const store = useShipStore()

const props = defineProps({
  item: { type: Object, required: true }
})

const percentage = 100
const value = computed({
  get() {
    return props.item.opacity * percentage
  },
  set(value) {
    value = value / percentage
    store.setParameter(props.item, 'opacity', value)
  }
})
</script>
<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn
        icon="mdi-eye-settings"
        class="float-right"
        size="small"
        style="background-color: transparent"
        variant="text"
        v-bind="props"
        @mousedown.stop
      >
      </v-btn>
    </template>

    <v-list density="compact">
      <v-list-item
        width="200"
        @click="store.setHide(props.item)"
        :prepend-icon="item.hide ? 'mdi-eye' : 'mdi-eye-off'"
      >
        <v-list-item-title class="pt-1">
          {{ item.hide ? 'Show' : 'Hide' }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canEdit" width="200" @click.stop>
        <v-list-item-title>
          <v-icon> mdi-opacity </v-icon>
          <span class="ml-1 mt-1"> Opacity </span>
        </v-list-item-title>
        <v-slider
          v-model.lazy="value"
          hide-details
          class="px-2 pb-2"
          min="20"
          max="100"
          tick-size="4"
          show-ticks
          :ticks="{
            20: '20%',
            100: '100%'
          }"
          style="z-index: 1"
          @mouseup="store.update(item)"
        >
        </v-slider>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
:deep(.v-list-item__spacer) {
  width: 4px !important;
}

:deep(.v-list-item__prepend) .v-icon {
  opacity: 1;
}
</style>
