import { defineStore } from 'pinia'
import { storeToRefs } from 'pinia/dist/pinia'
import { useAuthorizationStore } from '@/stores/authorization'
import API from '@/api/config/apiUrls'
import AppBaseStore from './appBaseStore'
import { useDjangoAuthStore } from '@/stores/authDjango'
import { useApi } from '@/api/config/useApi'
import { AuthModelUserBaseStore } from '@rhdhv/vue-user-management'

const { api } = useApi()

const baseUrl = API.CORE.PROJECT_USERS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)
const authModelBaseStore = new AuthModelUserBaseStore()
export const useProjectUserStore = defineStore({
  id: 'project_user',
  state: () => ({
    ...baseStore.state,
    ...authModelBaseStore.state,
    dialog: false
  }),
  actions: {
    ...baseStore.actions,
    ...authModelBaseStore.actions,
    toggleDialog() {
      this.dialog = !this.dialog
    },
    setCurrentItem() {
      const authStore = useDjangoAuthStore()

      const { user: currentUser } = storeToRefs(authStore)
      this.currentItem = this.items.find((item) => item.user === currentUser.value.id)
    },
    resetState() {
      this.items = []
    }
  },
  getters: {
    ...baseStore.getters,
    ...authModelBaseStore.getters,
    hasAuthModelInStore: () => {
      const authorizationStore = useAuthorizationStore()
      const { currentRoles } = storeToRefs(authorizationStore)

      return currentRoles.value.some((role) => role.auth_source === 'ProjectUser')
    }
  }
})
