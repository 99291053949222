<script setup>
import { useProjectUserStore } from '@/stores/project_user'

const store = useProjectUserStore()
import RHDHVButton from '@/components/common/RHDHVButton.vue'
</script>

<template>
  <v-card elevation="0" variant="flat">
    <v-card-title>
      User management
      <RHDHVButton
        class="ml-2 text-none"
        allowed="add_projectuser"
        auth-source="ProjectUser"
        prepend-icon="mdi-plus"
        @click="store.toggleAddUserDialog()"
      >
        Add
      </RHDHVButton>
    </v-card-title>
    <v-card-subtitle>
      Please configure the permissions for users within this project, add a new user via the
      <v-btn
        class="text-none"
        size="x-small"
        prepend-icon="mdi-plus"
        elevation="0"
        :ripple="false"
        style="cursor: default"
      >
        Add
      </v-btn>
      button.
    </v-card-subtitle>
  </v-card>
</template>

<style scoped>
.v-card-title {
  display: flex;
  align-items: center;
}
</style>
