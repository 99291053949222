import {
  ApiServiceHandler,
  EventBusInterceptor,
  UseService,
} from "@rhdhv/js-api-authentication-management";

import useEventsBus from "@/bus/eventBus";
import API from "@/api/config/apiUrls";

export const { emit } = useEventsBus();

const interceptor = new EventBusInterceptor(emit);

const baseURL = import.meta.env.VITE_APP_API_BASE_URL;
const projectApiServiceHandler = new ApiServiceHandler(
  baseURL,
  API.TOKEN.ACCESS,
  API.TOKEN.REFRESH,
  interceptor
);

export const useApi = () => {
  const api = new UseService(projectApiServiceHandler);
  return { api };
};
