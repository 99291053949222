<script setup>
import { useShipStore } from '@/stores/ship'
import { ref, watch, computed } from 'vue'
import FairleadTable from '../fairlead/FairleadTable.vue'
import TextField from '@/components/form/TextField.vue'
import ShipActionMenu from './ShipRowActionMenu.vue'
import { useAuthorizationStore } from '@/stores/authorization'
import { storeToRefs } from 'pinia/dist/pinia'

const store = useShipStore()
const authorizationStore = useAuthorizationStore()

const props = defineProps({
  item: { type: Object, required: true }
})

const { selectedItems } = storeToRefs(store)

const open = ref(false)

function setCurrentShip() {
  if (props.item.hide) {
    return store.setHide(props.item)
  }
  if (open.value) {
    store.setCurrentItem({})
  } else {
    store.setCurrentItem(props.item)
  }
  open.value = !open.value
  store.setShowLines(props.item, open.value)
}

const field = computed(() => {
  let eccentricity = { ...store.fields['eccentricity'] }
  eccentricity['min_value'] = -props.item.length_overall / 2
  eccentricity['max_value'] = props.item.length_overall / 2
  return eccentricity
})

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_calculation'
  })
)

watch(
  () => props.item.eccentricity,
  async () => {
    store.update(props.item)
  }
)

const isSelected = computed({
  get: () => {
    return selectedItems.value?.includes(props.item) || false
  },
  set: () => {
    store.toggleSelectedItem(props.item)
  }
})
</script>

<template>
  <tr
    v-ripple
    :class="{
      clickable: true,
      'selected-item': isSelected,
      opacity: item.hide
    }"
    @click.stop="setCurrentShip()"
  >
    <td style="width: 1%" class="px-0">
      <v-btn
        :icon="open ? `mdi-chevron-up` : `mdi-chevron-down`"
        color="#303030"
        :ripple="false"
        variant="text"
        @click.stop="setCurrentShip()"
      ></v-btn>
    </td>
    <td class="pl-0">
      <v-checkbox
        color="primary"
        density="compact"
        hide-details
        v-model="isSelected"
        @click.stop=""
        class="ml-n1"
      ></v-checkbox>
    </td>
    <td>{{ item.number }}</td>
    <td>{{ item.capacity }}</td>
    <td class="center">
      <TextField
        :disabled="!canEdit"
        :store="store"
        :field="field"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-update-on-error
        no-padding
        center
        font-size="14px"
      ></TextField>
    </td>
    <td>{{ item.length_overall }}</td>
    <td>{{ item.breadth }}</td>
    <td>{{ item.laden_draught }}</td>
    <td>{{ item.ballasted_draught }}</td>
    <td>{{ item.depth_main_deck_to_keel }}</td>
    <td>{{ item.forward_parallel_body_length }}</td>
    <td>{{ item.aftward_parallel_body_length }}</td>
    <td style="width: 1%">
      <ShipActionMenu :item="item"></ShipActionMenu>
    </td>
  </tr>
  <tr v-show="open">
    <td colspan="16" class="pa-0">
      <FairleadTable :ship="item" />
    </td>
  </tr>
</template>

<style scoped>
td {
  text-align: center;
}

.opacity {
  opacity: 0.5;
}

tr:hover {
  background-color: #f5f5f5;
}
</style>
