<script setup lang="ts">
import { computed, defineEmits, onMounted, type PropType, ref, toRef, watch } from 'vue'
import HelpTextTooltip from './HelpTextTooltip.vue'
import { toNumber } from 'lodash'

const props = defineProps({
  modelValue: { type: Object, required: true },
  itemKey: { type: String, required: false },
  field: { type: Object, required: true },
  label: { type: String, required: false },
  hideDetails: { type: Boolean, required: false, default: false },
  center: { type: Boolean, required: false, default: false },
  shrink: { type: Boolean, required: false, default: false },
  warning: { type: Boolean, required: false, default: false },
  warningRules: { type: Array<any>, required: false, default: [] },
  required: { type: Boolean, required: false, default: false },
  customErrorRules: { type: Array<Boolean | Function>, required: false, default: [] },
  noLabel: { type: Boolean, required: false, default: false },
  noUpdateOnError: { type: Boolean, required: false, default: false },
  showErrorWithoutUpdate: { type: Boolean, required: false, default: false },
  noPadding: { type: Boolean, required: false, default: false },
  noHelpText: { type: Boolean, required: false, default: false },
  density: {
    type: String as PropType<'default' | 'comfortable' | 'compact'>,
    required: false,
    default: 'default'
  },
  fractionDigits: { type: Number, required: false, default: 2 },
  step: { type: Number, required: false, default: 1 },
  fontSize: { type: String, required: false }
})

const emit = defineEmits<{
  update: []
  'update:modelValue': [Record<string, any>]
  'click:prepend-inner': []
}>()

const modelValue = toRef(props, 'modelValue')

const typeMapping = ref<{ [key: string]: string }>({
  float: 'number',
  integer: 'number',
  string: 'text'
})

const span = ref<HTMLElement | null>(null)
const fieldRef = ref(null)
const error = ref(false)
const fontRatio = ref(1)
const textFieldValue = ref('')

const itemKey = computed(() => {
  return props.itemKey ?? props.field.parameter
})

function setParameter(e: any) {
  let value = e.target.value
  const validation = validate(value)

  if (!isNaN(value) && props.field.type !== 'string') {
    value = toNumber(value)
  }
  error.value = !validation
  if (!validation) {
    if (props.noUpdateOnError) {
      textFieldValue.value = modelValue.value[itemKey.value]
      return
    }
    if (props.showErrorWithoutUpdate) {
      return
    }
  }
  modelValue.value[itemKey.value] = value
  emit('update:modelValue', modelValue.value)
  emit('update')
}

const label = computed(() => {
  return props.noLabel ? undefined : props.label ? props.label : props.field.label
})

watch(
  () => modelValue.value,
  () => {
    let value = modelValue.value[itemKey.value]
    if (typeof value === 'number') {
      value = value.toFixed(props.fractionDigits)
    }
    textFieldValue.value = value
  }
)

const shrinkStyle = computed(() => {
  return props.shrink ? `width:${textFieldValue.value?.length - 1}.5rem` : ''
})

const showErrorUnderline = computed(() => {
  return props.density === 'compact' && props.hideDetails && error.value
})

const noPaddingClass = computed(() => {
  return props.noPadding ? 'no-padding' : ''
})

const fieldInputCenterClass = computed(() => {
  return props.center ? 'field-input-center' : ''
})

const errorUnderlineClass = computed(() => {
  return showErrorUnderline.value ? 'border-bottom-red' : ''
})

const propRequiredRule = (value: any) => {
  if (props.required) {
    if (value === undefined || value === null) {
      return 'Required.'
    }
  }
  return true
}

const rulesDef = computed<any[]>(() => {
  const required = 'required' in props.field && props.field.required ? rules.value.required : true
  const maxValue = 'max_value' in props.field ? rules.value.maxValue : true
  const minValue = 'min_value' in props.field ? rules.value.minValue : true
  const customErrorRules = props.customErrorRules ?? []
  return [required, propRequiredRule, maxValue, minValue, ...customErrorRules]
})

const warningRulesDef = computed(() => {
  let message = ''
  props.warningRules.forEach((rule: any) => {
    if ('min_value' in rule && textFieldValue.value && textFieldValue.value < rule.min_value) {
      message = rule.message
    }
    if ('max_value' in rule && textFieldValue.value && textFieldValue.value > rule.max_value) {
      message = rule.message
    }
  })
  return message
})

function validate(value: any) {
  return rulesDef.value.every((func: any) => {
    return !(func !== true && func(value) !== true)
  })
}

const rules = ref({
  required: (value: number) => value === 0 || !!value || 'Required.',
  maxValue: (value: number) =>
    props.field.max_value === undefined ||
    value <= props.field.max_value ||
    `The max value is ${props.field.max_value}.`,
  minValue: (value: number) =>
    props.field.min_value === undefined ||
    value >= props.field.min_value ||
    `The min value is ${props.field.min_value}.`
})

onMounted(() => {
  textFieldValue.value = modelValue.value[itemKey.value]
  if (typeof modelValue.value[itemKey.value] === 'number') {
    textFieldValue.value = textFieldValue.value.toFixed(props.fractionDigits)
  }
  try {
    if (span.value) {
      if (
        span.value.clientWidth >
        (span.value.parentElement?.parentElement?.parentElement?.clientWidth ?? 1)
      ) {
        const extraWidthParent = 20
        fontRatio.value =
          ((span.value.parentElement?.parentElement?.parentElement?.clientWidth ?? 1) +
            extraWidthParent) /
          span.value.clientWidth
      }
    }
  } catch {
    /* empty */
  }
})
</script>

<template>
  <div v-if="field" @click.stop>
    <v-textarea
      v-if="field.field_type === 'textfield'"
      color="primary"
      :label="label"
      :type="typeMapping[field.type]"
      :hide-details="hideDetails"
      @change="setParameter($event)"
      v-model="textFieldValue"
      v-bind="$attrs"
    >
    </v-textarea>

    <v-text-field
      v-else
      @click:prepend-inner="emit('click:prepend-inner')"
      @click.stop
      :step="step"
      :rules="rulesDef"
      ref="fieldRef"
      color="primary"
      :class="`${noPaddingClass} ${errorUnderlineClass} ${fieldInputCenterClass} font-size`"
      :density="density"
      :type="typeMapping[field.type]"
      :hide-details="hideDetails"
      :style="`white-space: nowrap; ${shrinkStyle}`"
      @change="setParameter($event)"
      v-model="textFieldValue"
      v-bind="$attrs"
    >
      <template v-slot:label>
        <span :style="`font-size: ${fontRatio}em;`" ref="span">
          {{ label }}
        </span>
      </template>
      <template v-slot:append-inner>
        <div class="pt-1">
          <HelpTextTooltip v-if="field.help_text && !noHelpText" :text="field.help_text" />
        </div>
      </template>
      <template v-slot:details="{ isValid }">
        <template v-if="isValid">
          <div
            v-if="isValid.value && props.warning"
            class="pt-1"
            style="color: darkorange; white-space: break-spaces"
          >
            {{ warningRulesDef }}
          </div>
        </template>
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.no-padding :deep(.v-field__input) {
  padding: 0;
}

.v-text-field :deep(.v-input__details) {
  padding-left: 0;
  padding-bottom: 2px;
  padding-top: 2px;
  min-height: 12px;
  word-break: break-word;
}

.v-text-field :deep(.v-messages__message) {
  padding-left: 4px;
  position: fixed;
  background-color: white;
}

.field-input-center :deep(.v-field__input) {
  text-align: center;
}

.font-size :deep(.v-field__input) {
  font-size: v-bind(fontSize);
}
</style>
