import { defineStore, storeToRefs } from 'pinia'
import AppBaseStore from './appBaseStore'
import { MOORING_POINT_LABEL } from '../components/chart/chartLabels'
import { useFairleadStore } from './fairlead'
import hexToRgbA from '../utils/hex_to_rgba'
import { useCalculationStore } from '@/stores/calculation'
import { ISLAND } from '@/components/calculation/calculationParameterTypes'

import API from '@/api/config/apiUrls'

import { useApi } from '@/api/config/useApi'

const { api } = useApi()

const baseUrl = API.CORE.MOORING_POINTS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useMooringPointStore = defineStore({
  id: 'mooring_point',
  state: () => ({
    ...baseStore.state,
    labels: true,
    selectedItems: []
  }),
  actions: {
    ...baseStore.actions,
    toggleLabels() {
      this.labels = !this.labels
    },
    toggleSelectedItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item)
      } else {
        this.selectedItems.push(item)
      }
    },
    emptySelectedItems() {
      this.selectedItems = []
    }
  },
  getters: {
    ...baseStore.getters,
    getItemOnFairlead: (state) => {
      return (fairlead) => {
        return state.items.find((mooringPoint) => mooringPoint.id === fairlead.mooring_point)
      }
    },
    getItems: (state) => {
      const fairleadStore = useFairleadStore()
      const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())

      state.items && state.items.length
        ? state.items.forEach((item) => {
            const connectedFairleadItems = fairleadStore.itemsCalculatedAngle.filter(
              (fairLeadItem) => fairLeadItem.mooring_point === item.id
            )
            const maxHorizontalAngle = Math.max(
              ...connectedFairleadItems.map((fairlead) => fairlead.horizontal_angle)
            )
            const maxVerticalAngle = Math.max(
              ...connectedFairleadItems.map((fairlead) => fairlead.vertical_angle)
            )
            const fairleadMaxHorizontal = connectedFairleadItems.find(
              (fairlead) => fairlead.horizontal_angle === maxHorizontalAngle
            )
            const fairleadMaxVertical = connectedFairleadItems.find(
              (fairlead) => fairlead.vertical_angle === maxVerticalAngle
            )
            const mooringPointNameSuffix =
              currentCalculation.value.berth_type === ISLAND ? 'D' : 'P'

            item.name =
              (item.type === 'BERTHING' ? 'B' : 'M') + mooringPointNameSuffix + item.number
            item.max_horizontal_angle = fairleadMaxHorizontal
              ? fairleadMaxHorizontal.horizontal_angle
              : null
            item.max_vertical_angle = fairleadMaxVertical
              ? fairleadMaxVertical.vertical_angle
              : null
            item.max_horizontal_color = fairleadMaxHorizontal
              ? fairleadMaxHorizontal.horizontal_color
              : null
            item.max_vertical_color = fairleadMaxVertical
              ? fairleadMaxVertical.vertical_color
              : null
          })
        : []
      return state.items
    },
    getDataLabels(state) {
      const that = this
      if (!state.labels) {
        return null
      }
      return {
        clip: true,
        align: 'start',
        anchor: 'start',
        formatter: function (value) {
          return value.name
        },
        backgroundColor: function (context) {
          let index = context.dataIndex
          let item = that.getItems ? that.getItems[index] : null
          return item ? hexToRgbA(item.color, 0.1) : undefined
        },
        font: {
          weight: 'bold',
          size: '10'
        },
        labels: {
          value: {
            color: function (context) {
              let index = context.dataIndex
              let item = that.getItems ? that.getItems[index] : null
              return item ? item.color : undefined
            }
          }
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 1,
        padding: 6
      }
    },
    getPlot() {
      const that = this
      return this.getItems && this.getItems.length
        ? {
            label: MOORING_POINT_LABEL,
            fill: false,
            pointStyle: 'rectRounded',
            pointRadius: 8,
            borderColor: function (context) {
              let index = context.dataIndex
              let item = that.getItems ? that.getItems[index] : null
              return item ? item.color : undefined
            },
            backgroundColor: function (context) {
              let index = context.dataIndex
              let item = that.getItems ? that.getItems[index] : null

              return item ? item.color : undefined
            },
            pointBackgroundColor: function (context) {
              let index = context.dataIndex
              let item = that.getItems ? that.getItems[index] : null
              return item ? item.color : undefined
            },
            datalabels: this.getDataLabels,
            data: that.getItems ? that.getItems.map((item) => item) : [] // Map otherwise stack size exceeded issue
          }
        : []
    }
  }
})
