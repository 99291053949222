<script setup>
import { storeToRefs } from "pinia/dist/pinia";

const props = defineProps({
  name: { type: String, required: false },
  store: { type: Object, required: true },
});

async function deleteObj() {
  const deletedItem = await props.store.delete();
  if (deletedItem) {
    props.store.toggleDeleteDialog();
  }
}

const { deleteDialog, deleteLoading } = storeToRefs(props.store);
</script>

<template>
  <v-dialog
    v-model="deleteDialog"
    max-width="400"
    @click:outside="store.toggleDeleteDialog()"
  >
    <v-card>
      <v-card-title class="pt-6">Delete</v-card-title>
      <v-card-text>
        Delete <strong>{{ name ? name : "instance" }}</strong
        >?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="deleteLoading"
          color="red darken-1"
          text
          @click="deleteObj()"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
