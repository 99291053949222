<script setup>
import useEventsBus from "../../bus/eventBus";
import { watch, ref } from "vue";

const timeout = ref(5000);

const snackbar = ref(null);
const show = ref(null);

const { bus } = useEventsBus();

watch(
  () => bus.value.get("error"),
  (val) => {
    snackbar.value = val[0];
    show.value = true;
  }
);
</script>
<template>
  <v-snackbar
    v-model="show"
    max-width="600px"
    multi-line
    :timeout="timeout"
    color="red"
  >
    <div>
      <strong>Request failed with status code {{ snackbar.status }}</strong>
    </div>
    <div>{{ snackbar.statusText }}</div>
    <template #actions>
      <v-btn icon="mdi-close" size="small" @click.stop="show = false"></v-btn>
    </template>
  </v-snackbar>
</template>
