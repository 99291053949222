<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'

const props = defineProps({
  filteredItems: { type: Array, required: true },
  store: { type: Object, required: true }
})

const store = props.store
const { selectedItems } = storeToRefs(props.store)
const selectAll = computed({
  get() {
    const selectedIds = selectedItems.value?.map((item) => item.id) || []
    return props.filteredItems.every((item) => selectedIds.includes(item.id))
  },
  set: (value) => {
    if (value) {
      store.selectAllItems(props.filteredItems)
    } else {
      store.emptySelectedItems()
    }
  }
})
</script>

<template>
  <v-row align="center">
    <v-col>
      <v-checkbox
        v-model="selectAll"
        label="Select all"
        class="select-all-checkbox"
        color="primary"
        density="compact"
        hide-details
        v-if="props.filteredItems?.length"
      />
    </v-col>
  </v-row>
</template>

<style scoped></style>
