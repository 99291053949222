<script setup>
import HelpTextTooltip from '../form/HelpTextTooltip.vue'
import { TextField } from '@rhdhv/vue-component-library'
import { useCalculationStore } from '@/stores/calculation'
import { useOptimisationStore } from '@/stores/optimisation'
import { storeToRefs } from 'pinia'
import { CONTINUOUS } from '@/components/calculation/calculationParameterTypes'
import RHDHVButton from '@/components/common/RHDHVButton.vue'

const store = useCalculationStore()
const optimisationStore = useOptimisationStore()

const { currentItem } = storeToRefs(store)
const { generateLoading } = storeToRefs(optimisationStore)

function customValidatorMooringPointY(value) {
  return currentItem.value.berth_type === CONTINUOUS &&
    value > currentItem.value.y_location_platform
    ? 'Mooring point Y-coordinate must be smaller than platform Y-coordinate'
    : true
}
</script>

<template>
  <v-list-item class="pb-0 pt-2">
    <template #title>
      <span> Mooring point configuration </span>
      <RHDHVButton
        class="float-right"
        :loading="generateLoading"
        item-class="ml-0"
        allowed="add_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        size="small"
        elevation="0"
        prepend-icon="mdi-plus-box-multiple"
        @click="optimisationStore.generate()"
      >
        Generate
      </RHDHVButton>
    </template>

    <v-row class="mt-0">
      <v-col cols="6" class="pb-1">
        <TextField
          :store="store"
          :field="store.fields['maximum_number_of_lines']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col class="pb-1">
        <TextField
          :store="store"
          :field="store.fields['min_line_length']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pb-1">
        <TextField
          :store="store"
          :field="store.fields['start_mooring_point_x']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col class="pb-1">
        <TextField
          :store="store"
          :field="store.fields['mooring_point_spacing']"
          :model-value="currentItem"
          warning
          :warning-rules="[
            {
              min_value: 15,
              message: 'This is a relatively low spacing'
            },
            {
              max_value: 30,
              message: 'This is a relatively high spacing'
            }
          ]"
          @update="store.update()"
        ></TextField>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6">
        <TextField
          :custom-error-rules="[customValidatorMooringPointY]"
          custom-label="Mooring point Y-coordinate [m]"
          :store="store"
          :field="store.fields['mooring_point_y']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <v-list-item class="pb-0 pt-4" title="Quay deck size and location">
    <template #title>
      <span class="mr-3"> Berth platform size and location</span>
      <HelpTextTooltip
        :text="'Define the size and location of the berth (Y-coordinate is lateral distance of quay/deck front behind the berthing line)'"
        size="small"
      ></HelpTextTooltip>
    </template>
    <v-row class="pt-2 pb-0">
      <v-col class="pr-1">
        <TextField
          v-if="store.fields['quay_level']"
          :store="store"
          :field="store.fields['quay_level']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col class="pl-1 pr-1">
        <TextField
          v-if="store.fields['y_location_platform']"
          :store="store"
          :field="store.fields['y_location_platform']"
          :model-value="currentItem"
          :step="0.5"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col class="pl-1 pr-2">
        <TextField
          v-if="store.fields['platform_width']"
          style="z-index: 1"
          :store="store"
          :field="store.fields['platform_width']"
          :model-value="currentItem"
          :step="0.5"
          warning
          :warning-rules="[
            {
              min_value: 10,
              message: 'This is a relatively small platform width.'
            }
          ]"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col class="pl-0">
        <TextField
          v-if="store.fields['platform_length']"
          style="z-index: 1"
          :store="store"
          :field="store.fields['platform_length']"
          :model-value="currentItem"
          :step="0.5"
          warning
          :warning-rules="[
            {
              min_value: 10,
              message: 'This is a relatively small platform width.'
            }
          ]"
          @update="store.update()"
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>
</template>
