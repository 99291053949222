import BaseStore from "@rhdhv/vue-basestore";
import _ from "lodash";
import { BaseStoreActions, BaseStoreType } from "@/stores/types/baseStoreTypes";

// not used but eslint complains if it's not here
interface AppBaseStore<T> {
  state: BaseStoreType<T>;
  actions: BaseStoreActions<T>;
  getters: any;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging,@typescript-eslint/no-unused-vars
class AppBaseStore<T> extends BaseStore implements AppBaseStore<T> {
  constructor(r: string, a: never, l = null, i: string = "id") {
    super(r, a, l, i);
    this.state = {
      ...this.state,
      error: false,
      options: {},
      a: a,
      r: r,
      i: i,
      menu: false,
      menuCoordinates: { x: 0, y: 0 },
    };
    this.actions = {
      ...this.actions,
      async fetchOptions() {
        this.options = await this.a.service(this.r).options();
      },
      async postOrUpdate(item = null) {
        return this.currentItem.id
          ? await this.update(item, this.currentItem.id)
          : await this.create(item);
      },
      async update(item = null, id = null) {
        this.writeLoading = true;
        const updateItem = item ? item : this.currentItem;
        let responseData;
        const idNumber = id ? id : updateItem[this.i];
        try {
          responseData = await this.a
            .service(this.r)
            .patch(idNumber, updateItem);
          const index = this.items
            .map((item) => item[this.i])
            .indexOf(responseData[this.i]);
          this.items[index] = responseData;
        } catch (e) {
          this.writeLoading = false;
          return false;
        }
        this.writeLoading = false;
        return responseData;
      },
      toggleMenu(coordinates = null, item = null) {
        if (coordinates) {
          this.menuCoordinates = coordinates;
        }
        if (item) {
          this.setCurrentItem(item);
          this.menu = true;
        } else {
          this.menu = !this.menu;
        }
      },
      setError(bool) {
        this.error = bool;
      },
      setDeleteItem(e) {
        this.deleteItem = e;
      },
    };
    this.getters = {
      ...this.getters,
      fieldsList(state) {
        if (state.options.actions) {
          const writableFields = Object.entries(
            state.options.actions.POST
          )?.filter(([, value]: [string, any]) => !value.read_only);
          return writableFields?.map((item: any) => {
            item[1].parameter = item[0];
            return item[1];
          });
        } else {
          return [];
        }
      },
      fieldsFiltered(state) {
        return this.fieldsList?.filter((field) =>
          state.filterFields?.includes(field.parameter)
        );
      },
      fields(state) {
        return state.options?.actions?.POST;
      },
      itemsAvailable(state) {
        return state.items ? state.items.length > 0 : false;
      },
      optionsAvailable(state) {
        return !_.isEmpty(state.options);
      },
    };
  }
}

export default AppBaseStore;
