<script setup>
import { Speckle } from '@rhdhv/vue-speckle'
import { useProjectStore } from '@/stores/project'
import { computed } from 'vue'
import { useCalculationStore } from '@/stores/calculation'
import { storeToRefs } from 'pinia/dist/pinia'

const projectStore = useProjectStore()
const calculationStore = useCalculationStore()

const { speckleDrawer } = storeToRefs(projectStore)
const { currentItem } = storeToRefs(calculationStore)

function setExportDrawer() {
  projectStore.toggleDrawer()
  projectStore.toggleSpeckleDrawer()
}

const drawer = computed({
  get: () => speckleDrawer.value,
  set: (value) => {
    projectStore.toggleSpeckleDrawer(value)
  }
})
</script>

<template>
  <v-navigation-drawer v-model="drawer" scrim="rgba(0,0,0,0)" temporary elevation="2" width="500">
    <v-list class="pb-0">
      <v-list-item @click.stop="setExportDrawer()">
        <template #title>
          <v-card-title class="pl-0">
            <v-icon size="large" icon="custom:speckleLogoIcon"></v-icon>
            Speckle
          </v-card-title>
        </template>
        <template #append>
          <v-btn
            variant="text"
            :icon="!drawer ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click.stop="setExportDrawer()"
          ></v-btn>
        </template>
      </v-list-item>

      <v-list-item>
        <Speckle :id="currentItem.speckle" only-send />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
