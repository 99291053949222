import { defineStore } from 'pinia'
import { useOptimisationStore } from './optimisation'
import { angleInDegreesVertical, horizontalAngleFairlead } from '@/domain/angle'
import { useMooringPointStore } from './mooring_point'
import { useShipStore } from './ship'
import { storeToRefs } from 'pinia/dist/pinia'
import { useCalculationStore } from './calculation'
import hexToRgbA from '../utils/hex_to_rgba'
import { useWinchStore } from './winch'
import { calculateLineLength } from '@/domain/calculateLineLength'
import API from '@/api/config/apiUrls'
import AppBaseStore from './appBaseStore'

import { useApi } from '@/api/config/useApi'

const { api } = useApi()

const baseUrl = API.CORE.FAIRLEADS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useFairleadStore = defineStore({
  id: 'fair_lead',
  state: () => ({
    ...baseStore.state,
    labels: true,
    editedItems: [],
    showWinch: true,
    selectedItems: []
  }),
  actions: {
    ...baseStore.actions,
    toggleLabels() {
      this.labels = !this.labels
    },
    toggleShowWinch() {
      this.showWinch = !this.showWinch
    },
    addEditedItem(item) {
      if (!this.editedItems.includes(item)) {
        this.editedItems.unshift(item)
      }
    },
    removeEditedItem(item) {
      this.editedItems = this.editedItems.filter((editedItem) => editedItem.id !== item.id)
    },
    removeEditedItems(items) {
      this.editedItems = this.editedItems.filter(
        (editedItem) => !items.find((item) => item.id === editedItem.id)
      )
    },
    emptyEditedItems() {
      this.editedItems = []
    },
    swapItems(item1, item2) {
      const index1 = this.items.findIndex((item) => item.id === item1.id)
      const index2 = this.items.findIndex((item) => item.id === item2.id)
      const temp = this.items[index1]
      this.items[index1] = this.items[index2]
      this.items[index2] = temp
    },
    moveItem(item, index = 0) {
      const oldIndex = this.items.findIndex((i) => i.id === item.id)
      this.items.splice(index, 0, this.items.splice(oldIndex, 1)[0])
    },
    toggleSelectedItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item)
      } else {
        this.selectedItems.push(item)
      }
    },
    emptySelectedItems() {
      this.selectedItems = []
    },
    async bulkUpdate(items) {
      const bulkUpdateUrl = `bulk_update/`
      this.writeLoading = true
      try {
        await this.a.apiService.api(this.r + bulkUpdateUrl, 'PATCH', items)
        items.forEach((item) => {
          this.removeItem(item)
        })
      } catch (e) {
        console.error(e)
        this.writeLoading = false
        return false
      }
      this.writeLoading = false
    }
  },
  getters: {
    ...baseStore.getters,
    itemsCalculatedAngle: (state) => {
      state.items.forEach((item) => {
        const mooringPointStore = useMooringPointStore()
        const winchStore = useWinchStore()
        const optimisationStore = useOptimisationStore()
        const shipStore = useShipStore()
        const calculationStore = useCalculationStore()
        const { currentItem: currentCalculation } = storeToRefs(calculationStore)
        const shipItem = shipStore.getItems.find((shipItem) => shipItem.id === item.ship)
        const mooringPoint = mooringPointStore.getItemOnFairlead(item)
        if (shipItem) {
          item.calculated_z = currentCalculation.value.water_level + item.z - shipItem.draught
        }
        if (mooringPoint && shipItem) {
          let clonedItem = Object.assign({}, item) // TODO come up with a nicer solution soon
          clonedItem.x = shipItem.eccentricity + item.x
          item['horizontal_angle'] = clonedItem['horizontal_angle'] = horizontalAngleFairlead(
            mooringPoint,
            clonedItem
          )

          item['horizontal_color'] = optimisationStore.horizontalColorFairlead(
            clonedItem,
            mooringPoint
          )
          item['vertical_angle'] = angleInDegreesVertical(mooringPoint, clonedItem)
          item['vertical_color'] = optimisationStore.verticalColor(item)
          const winch = winchStore.getItemOnFairlead(item)
          item['line_length'] = calculateLineLength(mooringPoint, winch, clonedItem)
          if (item['line_length'] < currentCalculation.value.min_line_length) {
            item['line_length_color'] = 'red'
          } else {
            item['line_length_color'] = 'green'
          }
        }
      })
      return state.items
    },

    showLinesItems(state) {
      const shipStore = useShipStore()
      return state.items
        ? state.items.filter(
            (item) =>
              shipStore.getShowLinesItemsIds.includes(item.ship) && item.mooring_point !== null
          )
        : []
    },
    getDataLabels: (state) => {
      return (index, winch) => {
        // const that = this
        if (!state.labels) {
          return null
        }
        return {
          textAlign: 'end',
          clip: true,
          align: 'bottom',
          formatter: function (value, context) {
            let i = context.dataIndex
            if (i !== 1) {
              return ''
            }
            return index + 1
          },
          font: {
            weight: 'bold',
            size: '10'
          },
          offset: function () {
            return index % 2 ? 10 : 18
          },
          labels: {
            value: {
              color: function (context) {
                let index = context.dataIndex
                if (index !== 1) {
                  return undefined
                }
                let item = state.items[index]
                return item ? winch.color : undefined
              }
            }
          }
        }
      }
    },
    getMooringLines(state) {
      const shipStore = useShipStore()
      const mooringPointStore = useMooringPointStore()
      const winchStore = useWinchStore()
      let lines = []
      shipStore.getShowLinesItems.forEach((ship) => {
        if (ship.hide) {
          return null
        }
        const fairleads = this.showLinesItems.filter(
          (fairleadItem) => ship.id === fairleadItem.ship
        )
        fairleads.forEach((item, index) => {
          if (ship.hide) {
            return {}
          }
          const mooringPoint = mooringPointStore.items.find(
            (mooringPoint) => mooringPoint.id === item.mooring_point
          )
          const winch = winchStore.showLinesItems.find((winch) => winch.id === item.winch)
          if (!winch) {
            return {}
          }
          const winchCoordinate = state.showWinch ? [winch.x + ship.eccentricity, winch.y] : []
          const color = hexToRgbA(winch.color, ship.opacity)
          const lineNumber = index + 1
          const line = {
            label: 'L' + lineNumber,
            fill: false,
            pointRadius: 1,
            borderWidth: 1,
            showLine: true,
            borderColor: function () {
              return color
            },
            datalabels: this.getDataLabels(index, winch),
            data: mooringPoint
              ? [
                  [mooringPoint.x, mooringPoint.y],
                  [item.x + ship.eccentricity, item.y],
                  winchCoordinate
                ]
              : []
          }
          lines.push(line)
        })
      })
      return lines
    }
  }
})
