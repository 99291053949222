<script setup>
import { storeToRefs } from 'pinia/dist/pinia'

import { useShipApiStore } from '@/stores/shipApi'
import AddShipTableRow from './AddShipTableRow.vue'
import { computed } from 'vue'
import { useCalculationStore } from '@/stores/calculation'

const store = useShipApiStore()

const { items, validationStatus, fetchLoading: loading } = storeToRefs(store)
const { currentItem, options } = storeToRefs(useCalculationStore())

const fields = computed(() => {
  return options.value.actions.POST ?? {}
})

const typeName = computed(() => {
  const shipTypeField = fields.value['ship_type']
  return (
    shipTypeField?.choices.find((choice) => choice.value === currentItem.value.ship_type)
      ?.display_name ?? ''
  )
})

const validation = computed({
  get() {
    return validationStatus.value
  },
  set(value) {
    store.setValidationStatus(value)
    store.fetchItems()
  }
})
</script>

<template>
  <v-lazy class="fill-dialog">
    <v-table
      ref="table"
      :loading="loading"
      class="overflow-y-auto pb-3"
      style="height: 90vh"
      hover
      height="fill-height"
      fixed-header
    >
      <thead>
        <tr>
          <th class="text-left" colspan="5" style="width: 30%">Type: {{ typeName }}</th>
          <th class="text-center border-left-grey" colspan="2" style="width: 16%">Draught [m]</th>
          <th class="text-center border-left-grey" colspan="3" style="width: 24%">
            Depth to keel [m]
          </th>
          <th class="text-center pr-0 border-left-grey" colspan="2" style="width: 16%">
            Parallel body length [m]
          </th>
          <th class="text-center border-left-grey">Validation</th>
        </tr>
        <tr>
          <th class="text-center" style="width: 3%"></th>
          <th class="text-center" style="width: 12%">Name</th>
          <th class="text-center" style="width: 8%">Capacity [{{ store.getCapacityUnit }}]</th>
          <th class="border-left-grey text-center" style="width: 9%">Length overall [m]</th>
          <th class="text-center" style="width: 8%">Breadth [m]</th>
          <th class="text-center border-left-grey" style="width: 8%">Laden</th>
          <th class="text-center" style="width: 8%">Ballasted</th>
          <th class="text-center border-left-grey" style="width: 10%">Main deck</th>
          <th class="text-center" style="width: 9%">Forecastle</th>
          <th class="text-center" style="width: 9%">Poop</th>
          <th class="text-center border-left-grey" style="width: 7%">Aftward</th>
          <th class="text-center" style="width: 7%">Forward</th>
          <th class="text-center border-left-grey" style="width: 2%">
            <v-tooltip text="Preventing the retrieval of data from unverified ships">
              <template #activator="{ props }">
                <v-checkbox
                  v-bind="props"
                  v-model="validation"
                  color="primary"
                  hide-details
                  class="shrink"
                  @click.stop=""
                ></v-checkbox>
              </template>
            </v-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading" class="v-data-table-progress">
          <th colspan="999" style="text-align: center">
            <v-progress-linear indeterminate color="primary" />
          </th>
        </tr>
        <tr v-else-if="!items.length">
          <td class="text-center" colspan="999">No data available</td>
        </tr>
        <template v-else>
          <AddShipTableRow v-for="item in store.getScaledItems" :item="item" :key="item.id" />
        </template>
      </tbody>
    </v-table>
  </v-lazy>
</template>

<style scoped>
th :deep(.v-input__control),
th :deep(.v-selection-control) {
  display: unset !important;
}

.fill-dialog {
  height: calc(100% - 102px);
}
</style>
