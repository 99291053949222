import {
  ApiServiceHandler,
  Interceptor,
  UseService,
} from "@rhdhv/js-api-authentication-management";

const baseURL = import.meta.env.VITE_APP_SHIP_API_BASE_URL;

const defaultInterceptor = new Interceptor();
export const apiServiceHandler = new ApiServiceHandler(
  baseURL,
  "api/token/",
  "api/token/refresh/",
  defaultInterceptor,
  "accessShipApi",
  "refreshShipApi"
);

export const useShipApi = () => {
  const api = new UseService(apiServiceHandler);
  return { api };
};
