<script setup>
import CalculationIndexTitle from '@/components/calculation-index/CalculationIndexTitle.vue'
import { onMounted } from 'vue'
import { useCalculationStore } from '@/stores/calculation'
import { useRoute } from 'vue-router'
import CalculationCard from '@/components/calculation-index/CalculationCard.vue'
import { AppBackground } from '@rhdhv/vue-component-library'

const route = useRoute()
const store = useCalculationStore()

onMounted(async () => {
  await store.fetchOptions()
  await store.fetchItems({ project: route.params.id })
})
</script>
<template>
  <AppBackground class="pa-4">
    <CalculationIndexTitle />
    <CalculationCard class="ma-4" />
  </AppBackground>
</template>
