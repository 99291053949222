<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'
const authorizationStore = useAuthorizationStore()

const props = defineProps({
  authSource: { type: String, required: false, default: 'ProjectUser' },
  allowed: { type: [String, Array], required: false, default: '' },
  color: { type: String, default: 'primary' },
  size: { type: String, default: 'small' },
  width: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false }
})

// Emits custom events
const emit = defineEmits(['click'])

const handleButtonClick = () => {
  emit('click')
}

const isAuthorized = computed(() => {
  return checkAuthorization()
})

const checkAuthorization = () => {
  if (!props.allowed) {
    return true
  }
  return authorizationStore.hasServicePermission({
    authSource: props.authSource,
    allowed: props.allowed
  })
}
</script>

<template>
  <v-btn
    v-if="isAuthorized"
    :disabled="disabled"
    :loading="loading"
    :width="width"
    :color="color"
    :size="size"
    @click="handleButtonClick"
  >
    <slot></slot>
  </v-btn>
</template>
