<script setup>
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import { onMounted, ref, watch } from 'vue'
import { Scatter } from 'vue-chartjs'
import * as chartConfig from './chartConfig.js'
import { storeToRefs } from 'pinia/dist/pinia'
import { computed } from 'vue'

import { useFairleadStore } from '@/stores/fairlead'
import { useCalculationStore } from '@/stores/calculation'
import { useMooringPointStore } from '@/stores/mooring_point'
import { useWinchStore } from '@/stores/winch'
import { useShipContourStore } from '@/stores/ship_contour'
import { useShipStore } from '@/stores/ship'
import zoomPlugin from 'chartjs-plugin-zoom'
import datalabelPlugin from 'chartjs-plugin-datalabels'
import hexToRgbA from '../../utils/hex_to_rgba'
import useEventsBus from '../../bus/eventBus'
import { FAIRLEAD_LABEL, WINCH_LABEL } from './chartLabels'
import ChartControls from './ChartControls.vue'
import { ISLAND } from '@/components/calculation/calculationParameterTypes'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Filler, zoomPlugin)

const { items: shipItems } = storeToRefs(useShipStore())
const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())
const { items: fairleadItems } = storeToRefs(useFairleadStore())
const { items: winchItems } = storeToRefs(useWinchStore())
const { items: shipContourItems } = storeToRefs(useShipContourStore())

const fairleadStore = useFairleadStore()

const shipContours = computed(() => {
  const contourLines = []
  shipContourItems.value && shipItems.value
    ? shipItems.value.forEach((ship) => {
        if (ship.hide) {
          return null
        }
        const contours = shipContourItems.value.filter(
          (shipContourItem) => ship.id === shipContourItem.ship
        )
        const shipContour = {
          label: false,
          hover: {
            mode: false
          },
          pointRadius: 0,
          borderWidth: 1,
          animation: false,
          showLine: true,
          borderColor: `rgba(0,0,0,${ship.opacity})`,
          pointBackgroundColor: 'black',
          data: contours.map((item) => {
            return { x: item.x + ship.eccentricity, y: item.y }
          })
        }
        contourLines.push(shipContour)
      })
    : null
  return contourLines
})

const shipLabels = computed(() => {
  const shipLabels = []
  shipContourItems.value && shipItems.value
    ? shipItems.value.forEach((ship, index) => {
        if (ship.hide) {
          return null
        }
        const shipLabel = {
          label: 'Capacity ' + ship.capacity,
          objType: 'capacity',
          pointBackgroundColor: hexToRgbA('#000000', ship.opacity),
          pointRadius: 0,
          animation: false
        }
        shipLabels.push(shipLabel)
      })
    : null
  return shipLabels
})

const platform = computed(() => {
  const calculation = currentCalculation.value
  const x1 = calculation.berth_type === ISLAND ? calculation.x1 : -calculation.platform_length / 2
  const x2 = calculation.berth_type === ISLAND ? calculation.x2 : calculation.platform_length / 2

  return [
    {
      label: `Platform`,
      pointRadius: 2,
      borderWidth: 1,
      animation: false,
      fill: false,
      showLine: true,
      data: [
        [x1, calculation.y_location_platform],
        [x2, calculation.y_location_platform]
      ]
    },
    {
      label: `Platform`,
      pointRadius: 2,
      borderWidth: 1,
      animation: false,
      fill: '-1',
      showLine: true,
      backgroundColor: 'rgba(0,0,0,0.15)',
      pointBackgroundColor: 'rgba(0,0,0,0.15)',
      data: [
        [x1, calculation.y_location_platform - calculation.platform_width],
        [x2, calculation.y_location_platform - calculation.platform_width]
      ]
    }
  ]
})

const fairleads = computed(() => {
  let fairleadPoints = []
  fairleadItems.value && shipItems.value
    ? shipItems.value.forEach((ship) => {
        if (ship.hide) {
          return null
        }
        const fairleads = fairleadItems.value.filter(
          (fairleadItem) => ship.id === fairleadItem.ship
        )
        const fairlead = {
          label: FAIRLEAD_LABEL + ship.id,
          fill: false,
          animation: false,
          backgroundColor: function (context) {
            let index = context.dataIndex
            let item = fairleads[index]
            return item && item.winch_color ? hexToRgbA(item.winch_color, ship.opacity) : undefined
          },
          pointBackgroundColor: function (context) {
            let index = context.dataIndex
            let item = fairleads[index]
            return item && item.winch_color ? hexToRgbA(item.winch_color, ship.opacity) : undefined
          },
          data: fairleads.map((item) => {
            let clonedItem = Object.assign({}, item) // TODO come up with a nicer solution soon
            clonedItem.x = ship.eccentricity + item.x
            return clonedItem
          })
        }
        fairleadPoints.push(fairlead)
      })
    : null
  return fairleadPoints
})

const mooringPointStore = useMooringPointStore()

let mooringPoints = ref(mooringPointStore.getPlot)

const { bus } = useEventsBus()

watch(
  () => bus.value.get('chartEvent'),
  (val) => {
    const [item] = val
    triggerMouseEvent(item)
  }
)

// Make mooring point changes reactive in graph
mooringPointStore.$subscribe((mutation, state) => {
  mooringPoints.value = mooringPointStore.getPlot
  chart.value.chart.update()
})

function triggerMouseEvent({ id, label }) {
  let chartJs = chart.value.chart
  let index = chartJs.data.datasets.findIndex((item) => item.label === label)
  let data = chartJs.data.datasets.find((item) => item.label === label)
  let itemIndex = data.data.findIndex((item) => id === item.id)
  let meta = chartJs.getDatasetMeta(index),
    rect = chartJs.canvas.getBoundingClientRect(),
    point = meta.data[itemIndex].getCenterPoint(),
    evt = new MouseEvent('mousemove', {
      clientX: rect.left + point.x,
      clientY: rect.top + point.y
    }),
    node = chartJs.canvas
  node.dispatchEvent(evt)
}

const chartArea = ref(null)

const chart = ref(null)

let chartRatio = 1

function resetZoom() {
  chart.value.chart.resetZoom()
}

const largestShip = computed(() => {
  const lengths = shipItems.value.map((item) => item.length_overall)
  const maxLength = Math.max(...lengths)
  return shipItems.value.find((item) => item.length_overall === maxLength)
})

const winches = computed(() => {
  let winchPoints = []
  winchItems.value && shipItems.value
    ? shipItems.value.forEach((ship) => {
        if (ship.hide) {
          return null
        }
        const winches = winchItems.value.filter((winchItem) => ship.id === winchItem.ship)
        const winch = {
          label: WINCH_LABEL + ship.id,
          fill: false,
          animation: false,
          pointStyle: 'rect',
          borderColor: function (context) {
            let index = context.dataIndex
            let item = winches[index]
            return item ? hexToRgbA(item.color, ship.opacity) : undefined
          },
          backgroundColor: function (context) {
            let index = context.dataIndex
            let item = winches[index]
            return item ? hexToRgbA(item.color, ship.opacity) : undefined
          },
          pointBackgroundColor: function (context) {
            let index = context.dataIndex
            let item = winches[index]
            return item ? hexToRgbA(item.color, ship.opacity) : undefined
          },
          data: winches
            ? winches.map((item) => {
                let clonedItem = Object.assign({}, item) // TODO come up with a nicer solution soon
                clonedItem.x = ship.eccentricity + item.x
                return clonedItem
              })
            : []
        }
        winchPoints.push(winch)
      })
    : null
  return winchPoints
})

const dataSets = computed(() => {
  return {
    datasets: [
      mooringPoints.value,
      ...fairleads.value,
      ...shipLabels.value,
      ...winches.value,
      ...fairleadStore.getMooringLines,
      ...shipContours.value,
      ...platform.value
    ]
  }
})

const options = computed(() => {
  const scales = { ...chartConfig.options.scales }
  scales.x = {
    ...chartConfig.options.scales.x,
    suggestedMin: -chartScales.value,
    suggestedMax: chartScales.value,
    stepSize: 10
  }
  scales.y = {
    ...chartConfig.options.scales.y,
    suggestedMin: -chartScales.value * chartRatio,
    suggestedMax: chartScales.value * chartRatio,
    stepSize: 10
  }
  return { ...chartConfig.options, scales }
})

const chartScales = computed(() => {
  if (!largestShip.value) {
    return 100
  }
  return largestShip.value['length_overall'] / 2 + 25
})

onMounted(() => {
  chartRatio = chartArea.value.clientHeight / chartArea.value.clientWidth
})

const background_color_plugin = {
  id: 'background_color_plugin',
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { top, right, bottom, left, width, height },
      scales: { x, y }
    } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = 'white'
    ctx.fillRect(left, top, width, height)
    ctx.restore()
  }
}
</script>
<template>
  <div ref="chartArea" class="d-flex flex-column">
    <ChartControls @resetZoom="resetZoom()"></ChartControls>

    <Scatter
      id="chart"
      ref="chart"
      :data="dataSets"
      :options="options"
      :plugins="[background_color_plugin, datalabelPlugin]"
    >
    </Scatter>
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
