<script setup>
import { CONTINUOUS } from '@/components/calculation/calculationParameterTypes'
import MooringPointsTable from '@/components/mooring-points/MooringPointsTable.vue'
import RHDHVButton from '@/components/common/RHDHVButton.vue'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCalculationStore } from '@/stores/calculation'
import { useMooringPointStore } from '@/stores/mooring_point'
import BulkDeleteDialog from '@/components/dialog/BulkDeleteDialog.vue'

const mooringPointStore = useMooringPointStore()

const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())
const { items, selectedItems, deleteLoading, deleteDialog } = storeToRefs(mooringPointStore)

const hide = ref(true)

const mooringPointsWithHide = computed(() => {
  if (hide.value && currentCalculation.value.berth_type === CONTINUOUS) {
    return items.value.filter((item) => item.max_horizontal_angle)
  }
  return items.value
})

const deleteMooringPoints = async () => {
  await mooringPointStore.bulkDelete(selectedItems.value)
  mooringPointStore.toggleDeleteDialog()
  mooringPointStore.emptySelectedItems()
}
</script>

<template>
  <div class="fill-height">
    <v-row no-gutters class="pa-2">
      <v-col>
        <div v-if="currentCalculation.berth_type === CONTINUOUS">
          <v-checkbox
            v-model="hide"
            density="compact"
            label="Hide not used mooring points"
            color="primary"
            style="max-height: 36px"
            hide-details
          ></v-checkbox>
        </div>
      </v-col>
      <v-col>
        <div style="text-align: end">
          <RHDHVButton
            class="mr-2 text-none"
            allowed="add_mooringpoint"
            auth-source="ProjectUser"
            variant="tonal"
            prepend-icon="mdi-plus"
            size="default"
            @click="
              mooringPointStore.create({
                calculation: currentCalculation.id
              })
            "
          >
            Add mooring point
          </RHDHVButton>
          <RHDHVButton
            :disabled="selectedItems.length === 0"
            class="ml-2 text-none"
            allowed="delete_ship"
            auth-source="ProjectUser"
            variant="tonal"
            prepend-icon="mdi-delete-outline"
            size="default"
            @click="mooringPointStore.toggleDeleteDialog()"
          >
            Delete mooring point
          </RHDHVButton>
        </div>
      </v-col>
    </v-row>

    <div style="height: 68vh" class="d-flex flex-column">
      <MooringPointsTable :items="mooringPointsWithHide"></MooringPointsTable>
    </div>
    <BulkDeleteDialog
      :bulk-delete="true"
      :items-to-delete="selectedItems"
      :delete-dialog="deleteDialog"
      :delete-loading="deleteLoading"
      @delete="deleteMooringPoints()"
      @toggle-dialog="mooringPointStore.toggleDeleteDialog()"
    ></BulkDeleteDialog>
  </div>
</template>
