import { defineStore } from 'pinia'
import AppBaseStore from './appBaseStore'
import { useCalculationStore } from './calculation'
import { useFairleadStore } from './fairlead'
import { useMooringPointStore } from './mooring_point'

import API from '@/api/config/apiUrls'
import { useApi } from '@/api/config/useApi'

const { api } = useApi()

const baseUrl = API.CORE.OPTIMISATIONS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useOptimisationStore = defineStore({
  id: 'optimisation',
  state: () => ({
    ...baseStore.state,
    angleStrategyMax: {
      // TODO Uniformalize choicefields
      'Bow/Stern': 'max_bow_stern_line_angle',
      Breast: 'max_breast_line_angle',
      Spring: 'max_spring_line_angle',
      BOW_STERN: 'max_bow_stern_line_angle',
      BREAST: 'max_breast_line_angle',
      SPRING: 'max_spring_line_angle'
    },
    angleStrategyRecommended: {
      'Bow/Stern': 'recommended_bow_stern_line_angle',
      Breast: 'recommended_breast_line_angle',
      Spring: 'recommended_spring_line_angle',
      BOW_STERN: 'recommended_bow_stern_line_angle',
      BREAST: 'recommended_breast_line_angle',
      SPRING: 'recommended_spring_line_angle'
    },
    generateLoading: false,
    loading: false
  }),
  actions: {
    ...baseStore.actions,
    async optimise() {
      this.loading = true
      let url = 'mooring_points/optimise/'
      try {
        const calculationStore = useCalculationStore()
        const fairleadStore = useFairleadStore()
        const mooringPointStore = useMooringPointStore()
        let response = await api.service(url).create({
          calculation: calculationStore.currentItem.id
        })

        mooringPointStore.setItems(response)
        fairleadStore.fetchItems({
          ship__calculation: calculationStore.currentItem.id
        })
      } catch (e) {
        this.loading = false
        console.log(e)
      }
      this.loading = false
    },
    async generate() {
      this.generateLoading = true
      let url = 'mooring_points/generate/'
      try {
        const calculationStore = useCalculationStore()
        const fairleadStore = useFairleadStore()
        const mooringPointStore = useMooringPointStore()

        let response = await api.service(url).create({
          calculation: calculationStore.currentItem.id
        })

        mooringPointStore.setItems(response)
        fairleadStore.fetchItems({
          ship__calculation: calculationStore.currentItem.id
        })
      } catch (e) {
        this.generateLoading = false
        console.log(e)
      }
      this.generateLoading = false
    },
    async linkToFairleads() {
      const calculationStore = useCalculationStore()
      const fairleadStore = useFairleadStore()
      const mooringPointStore = useMooringPointStore()
      this.loading = true
      let url = 'mooring_points/link_to_fairleads/'
      try {
        let response = await api.service(url).create({
          calculation: calculationStore.currentItem.id
        })
        mooringPointStore.setItems(response)
        await fairleadStore.fetchItems({
          ship__calculation: calculationStore.currentItem.id
        })
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    }
  },

  getters: {
    ...baseStore.getters,
    horizontalColorFairlead: (state) => {
      return (fairlead, mooringPoint) => {
        const horizontalMaxAngle = state.currentItem[state.angleStrategyMax[fairlead.line_type]]
        const horizontalRecommendedAngle =
          state.currentItem[state.angleStrategyRecommended[fairlead.line_type]]
        if (
          fairlead.line_type === 'Bow/Stern' &&
          ((fairlead.x > 0 && fairlead.x > mooringPoint.x) ||
            (fairlead.x < 0 && fairlead.x < mooringPoint.x))
        ) {
          return 'red'
        } else if (
          fairlead.horizontal_angle > horizontalMaxAngle ||
          fairlead.horizontal_angle < fairlead.minimum_horizontal_angle
        ) {
          return 'red'
        } else if (fairlead.horizontal_angle > horizontalRecommendedAngle) {
          return 'orange'
        } else {
          return 'green'
        }
      }
    },
    horizontalColorMooringPoint: (state) => {
      return (mooringPoint) => {
        const horizontalMaxAngle = state.currentItem[state.angleStrategyMax[mooringPoint.line_type]]
        const horizontalRecommendedAngle =
          state.currentItem[state.angleStrategyRecommended[mooringPoint.line_type]]
        if (mooringPoint.max_horizontal_angle > horizontalMaxAngle) {
          return 'red'
        } else if (mooringPoint.max_horizontal_angle > horizontalRecommendedAngle) {
          return 'orange'
        } else {
          return 'green'
        }
      }
    },
    verticalColor: (state) => {
      return (fairlead) => {
        if (fairlead.vertical_angle > state.currentItem.max_vertical_angle) {
          return 'red'
        } else if (fairlead.vertical_angle > state.currentItem.recommended_vertical_angle) {
          return 'orange'
        } else {
          return 'green'
        }
      }
    }
  }
})
