<script setup>
import ShipTable from '@/components/ship/ShipTable.vue'
import RHDHVButton from '@/components/common/RHDHVButton.vue'
import BulkDeleteDialog from '@/components/dialog/BulkDeleteDialog.vue'
import AddShipDialog from '@/components/add-ship/AddShipDialog.vue'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import ExportExcelDialog from '@/components/calculation/ExportExcelDialog.vue'
import { useCalculationStore } from '@/stores/calculation'
import { useFairleadStore } from '@/stores/fairlead'

const shipStore = useShipStore()
const calculationStore = useCalculationStore()
const fairleadStore = useFairleadStore()

const { downloadLoading, currentItem: currentCalculation } = storeToRefs(calculationStore)

const {
  loading: shipLoading,
  deleteLoading: shipDeleteLoading,
  deleteDialog: shipDeleteDialog,
  selectedItems: selectedShips,
  itemsAvailable
} = storeToRefs(shipStore)

const { editedItems, fetchLoading } = storeToRefs(fairleadStore)

const addShipDialog = ref(false)
const exportDialog = ref(false)
const refresh = ref(false)

const deleteShips = async () => {
  await shipStore.bulkDelete(selectedShips.value)
  shipStore.toggleDeleteDialog()
  shipStore.emptySelectedItems()
}

const exportExcel = async () => {
  await calculationStore.export()
  exportDialog.value = false
}

watch(
  () => editedItems.value.length,
  (value) => {
    if (value > 0) {
      refresh.value = true
    }
  }
)

const refreshTable = async () => {
  if (currentCalculation.value) {
    await fairleadStore.fetchItems({ ship__calculation: currentCalculation.value.id })
    fairleadStore.emptyEditedItems()
    refresh.value = false
  }
}
</script>

<template>
  <div>
    <div class="pa-2" style="text-align: end">
      <RHDHVButton
        class="ml-2 text-none"
        allowed="add_ship"
        auth-source="ProjectUser"
        variant="tonal"
        prepend-icon="mdi-plus"
        size="default"
        @click="addShipDialog = true"
      >
        Add ship
      </RHDHVButton>
      <RHDHVButton
        :disabled="!itemsAvailable"
        class="ml-2 text-none"
        allowed="view_ship"
        auth-source="ProjectUser"
        variant="tonal"
        prepend-icon="mdi-export"
        size="default"
        @click="exportDialog = true"
      >
        Export excel
      </RHDHVButton>
      <RHDHVButton
        :disabled="!itemsAvailable || selectedShips.length === 0"
        class="ml-2 text-none"
        allowed="delete_ship"
        auth-source="ProjectUser"
        variant="tonal"
        prepend-icon="mdi-delete-outline"
        size="default"
        @click="shipStore.toggleDeleteDialog()"
      >
        Delete ship
      </RHDHVButton>
    </div>

    <v-alert v-if="refresh" type="warning" variant="tonal" class="ma-1" density="compact">
      The fairlead table was updated. Maybe the order of fairlead row has changed, please
      <RHDHVButton
        :disabled="!itemsAvailable"
        :loading="fetchLoading"
        class="text-none"
        allowed="view_fairlead"
        auth-source="ProjectUser"
        variant="tonal"
        prepend-icon="mdi-refresh"
        @click="refreshTable()"
      >
        Refresh
      </RHDHVButton>
      the table to update editing.
    </v-alert>

    <div v-if="!itemsAvailable && !shipLoading" class="pa-3" style="font-size: 14px">
      There are no design vessels available, please add a ship via the
      <v-btn
        class="mx-1 clickable text-none"
        style="cursor: default"
        :elevation="0"
        readonly
        :ripple="false"
        prepend-icon="mdi-plus"
        color="primary"
        size="small"
        variant="tonal"
      >
        Add ship
      </v-btn>
      button.
    </div>
    <v-card variant="text" style="height: 68vh" rounded="0" class="d-flex flex-column">
      <ShipTable></ShipTable>
    </v-card>

    <AddShipDialog
      :dialog="addShipDialog"
      @setDialog="
        (value) => {
          addShipDialog = value
        }
      "
    ></AddShipDialog>

    <BulkDeleteDialog
      :bulk-delete="true"
      :items-to-delete="selectedShips"
      :delete-dialog="shipDeleteDialog"
      :delete-loading="shipDeleteLoading"
      @delete="deleteShips()"
      @toggle-dialog="shipStore.toggleDeleteDialog()"
    ></BulkDeleteDialog>

    <ExportExcelDialog
      :model-value="exportDialog"
      :loading="downloadLoading"
      @export-excel="exportExcel()"
    ></ExportExcelDialog>
  </div>
</template>
