<script setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'
import { RHDHVForm } from '@rhdhv/vue-component-library'

const formValid = ref(false)

const props = defineProps({
  name: { type: String, required: false },
  store: { type: Object, required: true }
})

const { createUpdateDialog: dialog, writeLoading, currentItem } = storeToRefs(props.store)

const createOrUpdate = computed(() => {
  return currentItem.value['id']
    ? 'Update'
    : currentItem.value['duplicated_id']
    ? 'Duplicate'
    : 'Create'
})
</script>
<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card variant="flat">
      <v-card-title class="pt-6">{{ createOrUpdate }} {{ name }}</v-card-title>
      <v-card-text>
        <RHDHVForm :store="store" state-name="currentItem" v-model="formValid">
          <template v-slot:prepend>
            <slot name="prepend" />
          </template>
        </RHDHVForm>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :loading="writeLoading"
          color="primary"
          :disabled="!formValid"
          variant="text"
          @click="$emit('save')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
