<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  items: { type: Array, required: true },
  item: { type: [Object, null], required: true },
  canEdit: { type: Boolean, required: true },
  colorField: { type: String, required: false, default: 'color' }
})

const open = ref(false)

const emit = defineEmits(['rightClickEvent', 'set'])
</script>
<template>
  <v-chip
    v-ripple
    class="clickable"
    label
    link
    size="x-small"
    :color="item ? item[colorField] : null"
    :append-icon="canEdit ? (open ? 'mdi-chevron-up' : `mdi-chevron-down`) : ''"
  >
    <slot name="selection" :item="item"> </slot>
    <v-menu
      v-model="open"
      :open-on-click="true"
      :disabled="!canEdit"
      activator="parent"
      max-height="400"
    >
      <v-list density="compact">
        <v-list-item
          v-for="(optionItem, index) in items"
          :key="index"
          :value="index"
          @click="emit('set', optionItem)"
        >
          <v-chip
            v-ripple
            label
            size="x-small"
            :color="optionItem[colorField]"
            :append-icon="optionItem === item ? 'mdi-close-circle' : null"
            @click.right.prevent="emit('rightClickEvent', optionItem)"
          >
            <slot name="option" :item="optionItem"> </slot>
          </v-chip>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-chip>
</template>
