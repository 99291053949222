<script setup>
import { computed, onMounted, ref } from 'vue'
import { useCalculationStore } from '@/stores/calculation'
import { useRoute } from 'vue-router'
import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'
import { storeToRefs } from 'pinia'
import TableButtonGroup from '@/components/calculation-index/TableButtonGroup.vue'
import CalculationTable from '@/components/calculation-index/CalculationTable.vue'
import BulkActionDialog from '@/components/dialog/BulkActionDialog.vue'

const route = useRoute()
const store = useCalculationStore()

const { items, selectedItems, deleteLoading, deleteDialog, writeLoading, duplicateDialog } =
  storeToRefs(store)

onMounted(async () => {
  await store.fetchOptions()
  await store.fetchItems({ project: route.params.id })
})

const searchName = ref('')
const berthType = ref('')
const shipType = ref('')
const mooringSide = ref('')

const updateFilterParams = async (payload) => {
  store.emptySelectedItems()
  if (payload.searchName !== undefined) {
    searchName.value = payload.searchName
  }
  if (payload.berthType !== undefined) {
    berthType.value = payload.berthType
  }
  if (payload.shipType !== undefined) {
    shipType.value = payload.shipType
  }
  if (payload.mooringSide !== undefined) {
    mooringSide.value = payload.mooringSide
  }
}

const filteredItems = computed(() => {
  return items.value.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchName.value.toLowerCase()) &&
      (!berthType.value || item.berth_type === berthType.value) &&
      (!shipType.value || item.ship_type === shipType.value) &&
      (!mooringSide.value || item.mooring_side === mooringSide.value)
    )
  })
})

async function postOrUpdate() {
  const response = await store.allAction()
  if (response) {
    store.toggleCreateUpdateDialog()
  }
}

const deleteItems = async () => {
  await store.bulkDelete(selectedItems.value)
  store.toggleDeleteDialog()
  store.emptySelectedItems()
}

const duplicateItems = async () => {
  await store.bulkDuplicate(selectedItems.value)
  store.toggleDuplicateDialog()
  store.emptySelectedItems()
}
</script>
<template>
  <v-card border="opacity-35 sm" variant="text">
    <TableButtonGroup
      :filtered-items="filteredItems"
      :search-name="searchName"
      :berth-type="berthType"
      :ship-type="shipType"
      :mooring-side="mooringSide"
      @update-filter-params="updateFilterParams"
    />
    <CalculationTable :filtered-items="filteredItems" />
    <BulkActionDialog
      :bulk-action="true"
      :items-to-action="selectedItems"
      :loading="deleteLoading"
      :dialog="deleteDialog"
      @toggle-dialog="store.toggleDeleteDialog"
      @action="deleteItems"
    ></BulkActionDialog>

    <BulkActionDialog
      :bulk-action="true"
      action-text="Duplicate"
      bulk-action-text="Duplicate these {amount} calculations?"
      button-color="primary"
      :items-to-action="selectedItems"
      :loading="writeLoading"
      :dialog="duplicateDialog"
      @toggle-dialog="store.toggleDuplicateDialog"
      @action="duplicateItems"
    ></BulkActionDialog>

    <CreateOrUpdateDialog
      :store="store"
      name="calculation"
      @save="postOrUpdate()"
    ></CreateOrUpdateDialog>
  </v-card>
</template>
