import { defineStore } from 'pinia'
import { useCalculationStore } from './calculation'
import { useShipApi } from '@/api/config/useShipApi'
import API from '@/api/config/shipApiUrls'
import AppBaseStore from '@/stores/appBaseStore'

// Not the actual endpoint, but this works for the options call
const { api } = useShipApi()

const baseUrl = API.SHIPS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useShipApiStore = defineStore({
  id: 'shipApi',
  state: () => ({
    ...baseStore.state,
    selectedItems: [],
    pagination: null,
    count: 0,
    serverPagination: null,
    validationStatus: true,
    shipTypeCapacityUnits: {
      TANKER: 'DWT',
      LNG: '\u33A5',
      LPG: '\u33A5',
      DRY_BULK: 'DWT',
      DEFAULT: 'DWT'
    }
  }),
  actions: {
    ...baseStore.actions,
    resetState() {
      this.currentItem = {}
      this.items = []
      ;(this.selectedItems = []), (this.count = 0)
    },
    setSelectedItem(item) {
      this.selectedItems = item
    },
    setValidationStatus(status) {
      this.validationStatus = status
    },
    removeSelectedItems() {
      this.selectedItems = []
    },
    toggleSelectedItem(item) {
      if (this.selectedItems.includes(item)) {
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
        this.selectedItems.splice(index, 1)
      } else {
        this.selectedItems.push(item)
      }
    },
    async fetchItems() {
      this.fetchLoading = true
      const calculationStore = useCalculationStore()
      let approved = 'true'
      if (!this.validationStatus) {
        approved = null
      }

      this.items = await baseStore.actions.fetchItems({
        type: calculationStore.getCurrentShipType,
        mooring_side: calculationStore.getCurrentMooringSide,
        application: 'moor',
        //MoorMore Application search name in SHIP
        approved: approved
      })
      this.setCurrentItem(this.items[0])
      this.fetchLoading = false
    }
  },
  getters: {
    ...baseStore.getters,
    getSelectedItems(state) {
      return state.selectedItems.length ? state.selectedItems : []
    },
    getValidationStatus: () => (item) => {
      const shipValidation = item.validation_set.find((validation) => {
        return validation.appsearchname === 'moor'
      })
      return shipValidation ? shipValidation.approved : false
    },
    getCapacityUnit(state) {
      const calculationStore = useCalculationStore()
      return (
        state.shipTypeCapacityUnits[calculationStore.currentItem.ship_type] ??
        state.shipTypeCapacityUnits.DEFAULT
      )
    },
    getScaledItems(state) {
      state.items.forEach((item) => {
        const scaleFactorX = item.length_overall / item.original_length_overall
        const scaleFactorY = item.breadth / item.original_breadth
        item.winch_set.forEach((winch) => {
          winch.x = winch.original_x * scaleFactorX
          winch.y = winch.original_y * scaleFactorY
        })
        item.fairlead_set.forEach((fairlead) => {
          fairlead.x = fairlead.original_x * scaleFactorX
          fairlead.y = fairlead.original_y * scaleFactorY
        })
        item.shipcontour_set.forEach((contour) => {
          contour.x = contour.original_x * scaleFactorX
          contour.y = contour.original_y * scaleFactorY
        })
      })
      return state.items
    }
  }
})
