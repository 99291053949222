<script setup>
import TextField from '@/components/form/TextField.vue'
import { ChoiceField } from '@rhdhv/vue-component-library'

const { emit } = useEventsBus()

import { useMooringPointStore } from '@/stores/mooring_point'
import useEventsBus from '../../bus/eventBus'

import { MOORING_POINT_LABEL } from '../chart/chartLabels.js'
import { storeToRefs } from 'pinia/dist/pinia'
import { computed } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'

const store = useMooringPointStore()
const authorizationStore = useAuthorizationStore()

const { selectedItems } = storeToRefs(store)

const props = defineProps({
  item: { type: Object, required: true },
  lastRow: { type: Boolean, default: false }
})

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_mooringpoint'
  })
)

const getLineAngleStyle = (value) => {
  return value
    ? {
        'border-bottom-width': '2px',
        'border-bottom-style': 'solid',
        'border-bottom-color': value ? value : 'rgba(0, 0, 0, 0.1)'
      }
    : {}
}

const lastRowClass = computed(() => {
  return props.lastRow ? 'border-bottom-grey' : ''
})

const isSelected = computed({
  get: () => {
    return selectedItems.value?.includes(props.item) || false
  },
  set: () => {
    store.toggleSelectedItem(props.item)
  }
})
</script>

<template>
  <tr
    :class="{
      'selected-item': isSelected
    }"
  >
    <td :class="[lastRowClass, 'text-center px-1']">
      <v-checkbox
        color="primary"
        v-model="isSelected"
        density="compact"
        hide-details
        @click.stop=""
      ></v-checkbox>
    </td>
    <td style="width: 1%" :class="[lastRowClass]">
      <v-chip
        v-ripple
        class="clickable"
        label
        size="x-small"
        :color="item.color"
        @click.stop="emit('chartEvent', { id: item.id, label: MOORING_POINT_LABEL })"
      >
        {{ item.name }}
      </v-chip>
    </td>
    <td :class="[lastRowClass]">
      <ChoiceField
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['type']"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        @update="store.update(props.item)"
      ></ChoiceField>
    </td>
    <td :class="[lastRowClass, 'border-left-grey']">
      <TextField
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['x']"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        :center="true"
        font-size="15px"
        :step="0.5"
        @update="store.update(props.item)"
      ></TextField>
    </td>
    <td :class="[lastRowClass]">
      <TextField
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['y']"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        font-size="15px"
        :step="0.5"
        no-update-on-error
        @update="store.update(props.item)"
      ></TextField>
    </td>
    <td :class="[lastRowClass, 'border-right-grey']">
      <TextField
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['z']"
        :model-value="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        font-size="15px"
        :step="0.5"
        @update="store.update(props.item)"
      ></TextField>
    </td>
    <td
      :class="[lastRowClass, 'text-center']"
      :style="getLineAngleStyle(item.max_horizontal_color)"
    >
      <div>
        {{ item.max_horizontal_angle?.toFixed(2) }}
      </div>
    </td>
    <td :class="[lastRowClass, 'text-center']" :style="getLineAngleStyle(item.max_vertical_color)">
      <div>
        {{ item.max_vertical_angle?.toFixed(2) }}
      </div>
    </td>
  </tr>
</template>

<style scoped>
:deep(.v-field__input) {
  padding-top: 4px !important;
}
</style>
