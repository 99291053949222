<script setup>
import { useProjectStore } from "../../stores/project";
import { storeToRefs } from "pinia/dist/pinia";

const { currentItem } = storeToRefs(useProjectStore());
</script>

<template>
  <div class="pb-3">
    <v-row class="pt-2 pr-3">
      <v-col cols="10" class="pa-0">
        <v-tooltip location="bottom" open-delay="500" max-width="300px">
          <template #activator="{ props }">
            <v-card-title class="elip" v-bind="props">
              {{ currentItem.name }}
            </v-card-title>
          </template>
          <span> {{ currentItem.name }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="2" class="pt-4 pa-0"></v-col>
    </v-row>
    <div class="mt-2">
      <v-chip class="mr-3" prepend-icon="mdi-map-marker" size="x-small" label>
        {{ currentItem.location }}
      </v-chip>
      <span
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-family: Roboto, sans-serif;
        "
      >
        {{ currentItem.client }}
      </span>
    </div>
  </div>
</template>
