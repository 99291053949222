<script setup>
import SelectAllCheckBox from '@/components/common/SelectAllCheckBox.vue'
import { useCalculationStore } from '@/stores/calculation'
import { computed } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'
import { storeToRefs } from 'pinia/dist/pinia'

const store = useCalculationStore()
const authorizationStore = useAuthorizationStore()

const props = defineProps({
  filteredItems: { type: Array, required: true },
  searchName: { type: String, required: true },
  berthType: { type: String, required: true },
  shipType: { type: String, required: true },
  mooringSide: { type: String, required: true }
})
const { duplicateDialog, writeLoading, fields } = storeToRefs(store)

function handleDeleteSelectedCalculations() {
  store.toggleDeleteDialog()
}

const handleDuplicateSelectedCalculations = () => {
  store.toggleDuplicateDialog()
}

const canAddCalculation = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['add_calculation']
  })
})

const canDeleteCalculation = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['delete_calculation']
  })
})

const berthOptions = computed(() => {
  const options = fields.value?.berth_type.choices || []
  return [{ display_name: 'All', value: '' }, ...options]
})

const shipOptions = computed(() => {
  const options = fields.value?.ship_type.choices || []
  return [{ display_name: 'All', value: '' }, ...options]
})

const mooringSideOptions = computed(() => {
  const options = fields.value?.mooring_side.choices || []
  return [{ display_name: 'All', value: '' }, ...options]
})

const selectedName = computed({
  get: () => props.searchName,
  set: (value) => {
    emit('updateFilterParams', { searchName: value })
  }
})

const selectedBerthType = computed({
  get: () => props.berthType,
  set: (value) => {
    emit('updateFilterParams', { berthType: value })
  }
})

const selectedShipType = computed({
  get: () => props.shipType,
  set: (value) => {
    emit('updateFilterParams', { shipType: value })
  }
})

const selectedMooringSide = computed({
  get: () => props.mooringSide,
  set: (value) => {
    emit('updateFilterParams', { mooringSide: value })
  }
})

const emit = defineEmits(['updateFilterParams'])
</script>

<template>
  <v-row justify="center" align="center" class="pa-4">
    <v-col cols="12" md="2">
      <SelectAllCheckBox
        v-if="canAddCalculation || canDeleteCalculation"
        :filtered-items="props.filteredItems"
        :store="store"
      />
    </v-col>
    <v-col cols="12" md="7" class="d-flex">
      <v-text-field
        v-if="fields"
        v-model="selectedName"
        class="mx-1"
        label="Search"
        variant="outlined"
        hide-details
        density="compact"
        color="primary"
      ></v-text-field>
      <v-select
        v-if="fields"
        v-model="selectedBerthType"
        :items="berthOptions"
        variant="outlined"
        density="compact"
        class="mx-1"
        label="Berth type"
        hide-details
        color="primary"
        item-title="display_name"
        item-value="value"
        :return-object="false"
        style="max-width: 150px"
      ></v-select>
      <v-select
        v-if="fields"
        class="mx-1"
        v-model="selectedShipType"
        variant="outlined"
        :items="shipOptions"
        density="compact"
        label="Ship type"
        hide-details
        color="primary"
        item-title="display_name"
        item-value="value"
        :return-object="false"
        style="max-width: 150px"
      ></v-select>
      <v-select
        v-if="fields"
        class="mx-1"
        v-model="selectedMooringSide"
        :items="mooringSideOptions"
        variant="outlined"
        density="compact"
        label="Mooring side"
        hide-details
        color="primary"
        item-title="display_name"
        item-value="value"
        :return-object="false"
        style="max-width: 150px"
      ></v-select>
    </v-col>
    <v-col cols="12" md="3" class="d-flex justify-end">
      <v-btn
        v-if="canAddCalculation"
        color="primary"
        class="text-none my-1 mx-2"
        variant="tonal"
        prepend-icon="mdi-content-duplicate"
        @click="handleDuplicateSelectedCalculations()"
        :disabled="!store.selectedItems || !store.selectedItems.length"
        :loading="writeLoading && !duplicateDialog"
      >
        Duplicate
      </v-btn>
      <v-btn
        v-if="canDeleteCalculation"
        color="primary"
        class="text-none my-1 mx-2 mr-8"
        variant="tonal"
        prepend-icon="mdi-delete-outline"
        @click="handleDeleteSelectedCalculations()"
        :disabled="!store.selectedItems || !store.selectedItems.length"
      >
        Delete
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped></style>
