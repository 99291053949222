<script setup>
import { TextField, ChoiceField } from '@rhdhv/vue-component-library'
import { useCalculationStore } from '@/stores/calculation'
import { storeToRefs } from 'pinia/dist/pinia'
import { computed, watch } from 'vue'
import { useShipStore } from '@/stores/ship'
import { useShipApiStore } from '@/stores/shipApi'
import { CONTINUOUS, HIGH_WATER, ISLAND } from '@/components/calculation/calculationParameterTypes'
import CalculationFormIsland from '@/components/calculation/CalculationFormIsland.vue'
import CalculationFormContinuous from '@/components/calculation/CalculationFormContinuous.vue'

const { currentItem, items: calculationItems } = storeToRefs(useCalculationStore())
const { items } = storeToRefs(useShipStore())

const store = useCalculationStore()
const shipApiStore = useShipApiStore()

watch(
  () => currentItem.value.mooring_side,
  (newValue) => {
    if (newValue) {
      shipApiStore.resetState()
      shipApiStore.fetchItems()
    }
  }
)

watch(
  () => currentItem.value.ship_type,
  (newValue) => {
    if (newValue) {
      shipApiStore.resetState()
      shipApiStore.fetchItems()
    }
  }
)

const waterLevelParameter = computed(() => {
  return currentItem.value.high_low_water === HIGH_WATER ? 'high_water_level' : 'low_water_level'
})

const updateHighLowWater = async () => {
  await store.update()
  const updatedCurrentItem = calculationItems.value.find((item) => item.id === currentItem.value.id)
  if (updatedCurrentItem) {
    store.setParameter(currentItem.value, 'water_level', updatedCurrentItem.water_level)
  }
}
</script>

<template>
  <v-list-item class="pb-0 pt-4">
    <v-row>
      <v-col>
        <ChoiceField
          v-if="store.fields['berth_type']"
          :store="store"
          :field="store.fields['berth_type']"
          :model-value="currentItem"
          hide-details
          @update="store.update()"
        ></ChoiceField>
      </v-col>
      <v-col>
        <ChoiceField
          v-if="store.fields['ship_type']"
          :disabled="items.length > 0"
          :store="store"
          :field="store.fields['ship_type']"
          :model-value="currentItem"
          hide-details
          @update="store.update()"
        ></ChoiceField>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <TextField
          v-if="store.fields['actual_berthing_orientation']"
          :store="store"
          :field="store.fields['actual_berthing_orientation']"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
      <v-col>
        <ChoiceField
          v-if="store.fields['mooring_side']"
          :disabled="items.length > 0"
          :store="store"
          :field="store.fields['mooring_side']"
          :model-value="currentItem"
          @update="store.update()"
        ></ChoiceField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <v-list-item class="pb-0 pt-4">
    <v-row>
      <v-col cols="6">
        <ChoiceField
          v-if="store.fields['high_low_water']"
          :store="store"
          :field="store.fields['high_low_water']"
          :model-value="currentItem"
          @update="updateHighLowWater()"
        ></ChoiceField>
      </v-col>
      <v-col>
        <TextField
          v-if="currentItem.high_low_water === HIGH_WATER"
          :store="store"
          :field="store.fields['high_water_level']"
          :step="0.1"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
        <TextField
          v-else
          :store="store"
          :field="store.fields['low_water_level']"
          :step="0.1"
          :model-value="currentItem"
          @update="store.update()"
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <CalculationFormIsland v-if="currentItem.berth_type === ISLAND" />
  <CalculationFormContinuous v-if="currentItem.berth_type === CONTINUOUS" />
</template>
