<script setup>
import { storeToRefs } from 'pinia/dist/pinia'

import { useOptimisationStore } from '@/stores/optimisation'
import { computed } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'
import TextField from '@/components/form/TextField.vue'

const { currentItem } = storeToRefs(useOptimisationStore())

const store = useOptimisationStore()

const authorizationStore = useAuthorizationStore()

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: 'change_optimisation'
  })
)
</script>

<template>
  <v-card
    variant="flat"
    :disabled="!canEdit"
    height="100%"
    style="border-radius: 0"
    class="d-flex flex-column fill-height"
  >
    <v-table density="compact" no-label no-padding hover class="d-flex flex-column">
      <thead>
        <tr>
          <th class="text-left">Allowable line angles</th>
          <th class="text-center">Recommended angle (°)</th>
          <th class="text-center">Maximum (°)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="th">Horizontal - breast lines</td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['recommended_breast_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            >
            </TextField>
          </td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['max_breast_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
        </tr>
        <tr>
          <td class="th">Horizontal - spring lines</td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['recommended_spring_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['max_spring_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
        </tr>
        <tr>
          <td class="th">Horizontal - bow/stern lines</td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['recommended_bow_stern_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['max_bow_stern_line_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
        </tr>
        <tr>
          <td class="th">Vertical - all lines</td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['recommended_vertical_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
          <td>
            <TextField
              hide-details
              :store="store"
              :field="store.fields['max_vertical_angle']"
              :model-value="currentItem"
              density="compact"
              variant="plain"
              no-label
              no-padding
              center
              font-size="14px"
              @update="store.update()"
            ></TextField>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>
