import { defineStore } from 'pinia'
import AppBaseStore from './appBaseStore'
import API from '@/api/config/apiUrls'

import { useApi } from '@/api/config/useApi'

const { api } = useApi()
const baseUrl = API.ROLES.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useAuthorizationStore = defineStore({
  id: 'authorization',
  state: () => ({
    ...baseStore.state,
    currentRoles: []
  }),
  actions: {
    ...baseStore.actions,
    setCurrentRole(roleIds) {
      const roles = []
      roleIds.forEach((roleId) => {
        const role = this.getCurrentRole(roleId)
        if (role) {
          roles.push(role)
        }
      })
      this.currentRoles = roles
    },
    getCurrentRole(roleId) {
      return this.items.find((item) => item.id === roleId)
    },
    hasServicePermission({ authSource, allowed }) {
      const authRoles = this.currentRoles.find((role) => role.auth_source === authSource)
      return authRoles
        ? authRoles.permissions.some((permission) => allowed.includes(permission))
        : false
    },
    resetState() {
      this.items = []
      this.currentRoles = []
    },
    async getServicePermissionByRole({ roleItems, permission }) {
      this.resetState()
      await this.fetchItems()
      const authPermission = []
      roleItems.forEach((roleItem) => {
        const authPermissionItem = []
        permission.forEach((permissionItem) => {
          const authRoles = this.getCurrentRole(roleItem.role)
          if (!authRoles) return
          const hasPermission = authRoles.permissions.includes(permissionItem)
          if (hasPermission) {
            const p = permissionItem.split('_')[0]
            authPermissionItem.push(p)
          }
        })
        authPermission.push({
          id: roleItem.id,
          role: roleItem.role,
          permissions: authPermissionItem
        })
      })
      return authPermission
    }
  },
  getters: {
    ...baseStore.getters
  }
})
