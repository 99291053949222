<script setup>
import { storeToRefs } from 'pinia/dist/pinia'

import { useFairleadStore } from '@/stores/fairlead'
import { computed } from 'vue'
import FairleadTableRow from './FairleadTableRow.vue'
import { useWinchStore } from '@/stores/winch'
import BulkDeleteDialog from '@/components/dialog/BulkDeleteDialog.vue'

const store = useFairleadStore()

const { itemsCalculatedAngle, editedItems, selectedItems, writeLoading, deleteDialog } =
  storeToRefs(store)
const { items: winchItems } = storeToRefs(useWinchStore())

const props = defineProps({
  ship: { type: Object, required: true }
})

const filterItems = computed(() => {
  return itemsCalculatedAngle.value.filter((item) => {
    return (
      item.ship === props.ship.id &&
      (item.winch || editedItems.value.find((editedItem) => editedItem.id === item.id))
    )
  })
})

const filterWinchItems = computed(() => {
  return winchItems.value.filter((item) => item.ship === props.ship.id)
})

const unselectedItems = computed(() => {
  return itemsCalculatedAngle.value.filter((item) => {
    return item.ship === props.ship.id && !item.winch
  })
})

const hideFairleads = computed(() => {
  return unselectedItems.value.filter(
    (item) => editedItems.value.findIndex((editedItem) => editedItem.id === item.id) === -1
  )
})

const addNewFairlead = () => {
  if (hideFairleads.value.length) {
    store.moveItem(hideFairleads.value[0])
    store.addEditedItem(hideFairleads.value[0])
  }
}

const removeItems = async () => {
  selectedItems.value.forEach((item) => {
    store.setParameter(item, 'mooring_point', null)
    store.setParameter(item, 'winch', null)
    store.setParameter(item, 'line_type', null)
    store.setParameter(item, 'color', null)
    store.setParameter(item, 'horizontal_angle', NaN)
    store.setParameter(item, 'horizontal_color', null)
    store.setParameter(item, 'vertical_angle', NaN)
    store.setParameter(item, 'vertical_color', null)
  })
  await store.bulkUpdate(selectedItems.value)
  store.removeEditedItems(selectedItems.value)
  store.emptySelectedItems()
  store.toggleDeleteDialog()
}
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-table v-if="itemsCalculatedAngle && store.optionsAvailable" density="compact" hover>
      <thead>
        <tr>
          <th colspan="6" class="border-bottom-grey border-right-grey">
            <v-btn
              class="text-none"
              size="small"
              variant="tonal"
              prepend-icon="mdi-plus"
              :disabled="!hideFairleads.length"
              @click="addNewFairlead"
            >
              Add new fairlead
            </v-btn>
            <v-btn
              class="text-none ml-3"
              size="small"
              variant="tonal"
              prepend-icon="mdi-delete-outline"
              :disabled="!selectedItems.length"
              @click="store.toggleDeleteDialog()"
            >
              Remove fairleads
            </v-btn>
          </th>
          <th colspan="4" class="text-center border-bottom-grey border-right-grey">
            Fairlead properties
          </th>
          <th colspan="3" class="text-center border-bottom-grey border-right-grey">
            Resulting line properties
          </th>
        </tr>
        <tr>
          <th style="width: 4%"></th>
          <th class="text-center px-0" style="width: 2%">#</th>
          <th class="text-center pr-0">Fairlead</th>
          <th class="text-center pr-0">Winch</th>
          <th class="text-center pr-0">Dolphin</th>
          <th class="text-center">Line type</th>
          <th class="text-center pr-0 border-left-grey">X [m]</th>
          <th class="text-center pr-0">Y [m]</th>
          <th class="text-center border-right-grey">Z [m CD]</th>
          <th style="width: 10%" class="text-center border-right-grey pl-1 pr-1">Minimal (°)</th>
          <th class="text-center border-right-grey pl-1 pr-1">Length</th>
          <th class="text-center pr-0" style="width: 2%">Horizontal (°)</th>
          <th class="text-center" style="width: 2%">Vertical (°)</th>
        </tr>
      </thead>
      <tbody v-if="filterItems.length" class="text-center fill-height pb-3">
        <template v-for="(item, index) in filterItems">
          <FairleadTableRow
            :item="item"
            :index="index"
            :winch-items="filterWinchItems"
            :fairlead-items="unselectedItems"
          />
        </template>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="999" style="text-align: center">No data available</td>
        </tr>
      </tbody>
    </v-table>
    <BulkDeleteDialog
      :bulk-delete="true"
      :items-to-delete="selectedItems"
      :delete-dialog="deleteDialog"
      :delete-loading="writeLoading"
      @delete="removeItems"
      @toggle-dialog="store.toggleDeleteDialog()"
    >
      <template #info>
        <v-alert type="warning" variant="tonal" class="ma-1" density="compact">
          This action will remove the connection between the selected fairleads and the mooring
          point. These fairleads will be available for reconnection.
        </v-alert>
      </template>
    </BulkDeleteDialog>
  </div>
</template>

<style scoped>
td {
  text-align: center;
}

th {
  text-align: center;
}
</style>
