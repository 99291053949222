import API from "../api/config/apiUrls.js";

import { defineStore } from "pinia";
import { useApi } from "@/api/config/useApi";
import { useShipApi } from "@/api/config/useShipApi.ts";

const { api } = useApi();
const { api: shipApi } = useShipApi();

export const useDjangoAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    isInitDone: true,
  }),
  actions: {
    async authenticateShipApi() {
      const url = "ships/mlgships_authorization";
      try {
        const response = await api.service(url).get();
        if (!response) {
          return;
        }
        const accessToken = response.access;
        const refreshToken = response.refresh;
        if (accessToken && refreshToken) {
          localStorage.setItem("accessShipApi", accessToken);
          localStorage.setItem("refreshShipApi", refreshToken);
          await shipApi.apiService.authenticationHandler
            .setAccessTokenInHeaderFromStore()
            .then();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async authenticate({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) {
      await api.apiService.authenticationHandler.authenticate(email, password);
      const userResponse = await api.service(API.CORE.USERS.CURRENT_USER).get();
      return { user: userResponse };
    },
    async reAuthenticate() {
      await api.apiService.authenticationHandler.reauthenticate();
      try {
        const userResponse = await api
          .service(API.CORE.USERS.CURRENT_USER)
          .get();
        return { user: userResponse };
      } catch (e) {
        return { user: null };
      }
      return { user: null };
    },
    async getPromise() {},
    async logout() {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("accessShipApi");
      localStorage.removeItem("refreshShipApi");
      this.user = null;
    },
  },
});
