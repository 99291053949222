const baseURL = import.meta.env.VITE_APP_API_BASE_URL

const API = {
  TOKEN: {
    ACCESS: `${baseURL}api/token/`,
    REFRESH: `${baseURL}api/token/refresh/`
  },
  SPECKLE: {
    BASE: `${baseURL}speckle`,
    SEND: `${baseURL}speckle/send/`
  },
  ROLES: {
    INDEX: `${baseURL}roles/`
  },
  CORE: {
    PROJECTS: {
      INDEX: `${baseURL}projects/`
    },
    CALCULATIONS: {
      INDEX: `${baseURL}calculations/`
    },
    FAIRLEADS: {
      INDEX: `${baseURL}fair_leads/`
    },
    WINCHES: {
      INDEX: `${baseURL}winches/`
    },
    MOORING_POINTS: {
      INDEX: `${baseURL}mooring_points/`
    },
    SHIP_CONTOURS: {
      INDEX: `${baseURL}ship_contours/`
    },
    SHIPS: {
      INDEX: `${baseURL}ships/`
    },
    OPTIMISATIONS: {
      INDEX: `${baseURL}optimisations/`
    },
    PROJECT_USERS: {
      INDEX: `${baseURL}project_users/`
    },
    USERS: {
      INDEX: `${baseURL}users`,
      CURRENT_USER: `${baseURL}users/current_user/`
    }
  }
}

export default API
