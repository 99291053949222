<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 416 314"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-5895,-5112)">
      <g id="Artboard1" transform="matrix(1,0,0,1,4187.76,4786.57)">
        <rect x="0" y="0" width="3840" height="2160" style="fill: none" />
        <g transform="matrix(0.998475,-0.0552112,0,1.00153,504.711,-136.152)">
          <rect
            x="1294.87"
            y="614.156"
            width="204.453"
            height="204.719"
            style="fill: rgb(4, 126, 251)"
          />
        </g>
        <g
          transform="matrix(0.989814,-0.0547323,0.524518,0.471524,802.293,120.929)"
        >
          <rect
            x="643.94"
            y="618.105"
            width="206.242"
            height="64.29"
            style="fill: rgb(123, 188, 255)"
          />
        </g>
        <g transform="matrix(0.362077,0.325495,0,1.34467,1135,426.883)">
          <rect
            x="1736.88"
            y="-457.431"
            width="93.132"
            height="152.477"
            style="fill: rgb(49, 59, 207)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
