<script setup>
import { storeToRefs } from 'pinia/dist/pinia'
import { ISLAND } from '@/components/calculation/calculationParameterTypes'

import MooringPointsTableRow from './MooringPointsTableRow.vue'
import { useMooringPointStore } from '@/stores/mooring_point'
import { useCalculationStore } from '@/stores/calculation'
import HelpTextTooltip from '../form/HelpTextTooltip.vue'
import NoDataBar from '@/components/calculation-index/NoDataBar.vue'

const props = defineProps({
  items: { type: Array, required: true }
})
const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())

const mooringPointStore = useMooringPointStore()
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-divider />
    <v-table
      class="pa-0 ma-0 d-flex flex-column overflow-y-auto pb-1"
      hover
      density="compact"
      fixed-header
      height="fill-height"
    >
      <thead>
        <tr>
          <th class="text-center" :colspan="3"></th>
          <th class="text-center border-left-grey border-right-grey" colspan="3">Coordinates</th>
          <th class="text-center" colspan="2">Maximum line angle</th>
        </tr>

        <tr>
          <th></th>
          <th>
            <HelpTextTooltip
              color="black"
              text="'MD' stands for Mooring Dolphin, 'BD' stands for Berthing Dolphin"
            ></HelpTextTooltip>
            <span class="ml-1"> ID </span>
          </th>
          <th class="text-center" style="width: 150px">Type</th>
          <th class="text-center border-left-grey">X [m]</th>
          <th class="text-center">Y [m]</th>
          <th class="text-center border-right-grey">Z [m CD]</th>
          <th class="text-center">Horizontal (°)</th>
          <th class="text-center">Vertical (°)</th>
        </tr>
      </thead>
      <tbody v-if="items.length">
        <template v-for="(item, index) in items" :key="item.id">
          <MooringPointsTableRow :item="item" :last-row="index === items.length - 1" />
        </template>
      </tbody>
      <tbody v-else-if="mooringPointStore.itemsAvailable">
        <tr>
          <NoDataBar
            text="No mooring points available, click 'Show not used mooring points' to show them"
          >
          </NoDataBar>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="999" class="text-center" style="font-size: 13px">
            <template v-if="currentCalculation.berth_type === ISLAND">
              There are no mooring points available, please select
              <v-btn
                class="mx-1 clickable text-none"
                style="cursor: default"
                :elevation="0"
                readonly
                :ripple="false"
                prepend-icon="mdi-refresh"
                color="primary"
                size="x-small"
                variant="elevated"
              >
                Optimise
              </v-btn>
              to generate the mooring points or add them manually.
            </template>
            <template v-else>
              There are no mooring points available, please select
              <v-btn
                class="mx-1 clickable text-none"
                style="cursor: default"
                :elevation="0"
                readonly
                :ripple="false"
                prepend-icon="mdi-plus-box-multiple"
                color="primary"
                size="small"
                variant="elevated"
              >
                Generate
              </v-btn>
              in the input tab, to generate the mooring points or add them manually.
            </template>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped></style>
