<script setup>
import HelpTextTooltip from '../form/HelpTextTooltip.vue'

import { storeToRefs } from 'pinia'

import { useCalculationStore } from '@/stores/calculation'
import { TextField } from '@rhdhv/vue-component-library'

const store = useCalculationStore()

const { currentItem } = storeToRefs(store)
</script>

<template>
  <div>
    <v-list-item class="pb-0 pt-2">
      <v-row class="mt-0">
        <v-col>
          <TextField
            v-if="store.fields['mooring_point_level']"
            no-help-text
            :store="store"
            :field="store.fields['mooring_point_level']"
            :model-value="currentItem"
            :step="0.1"
            warning
            :warning-rules="[
              {
                min_value: currentItem.water_level,
                message: `Mooring dolphin level is lower than the water level`
              },
              {
                max_value: currentItem.water_level + 10,
                message: `Mooring dolphin level larger than 10m above water level are relatively high`
              }
            ]"
            @update="store.update()"
          ></TextField>
        </v-col>
        <v-col>
          <TextField
            v-if="store.fields['berthing_point_level']"
            no-help-text
            :store="store"
            :field="store.fields['berthing_point_level']"
            :model-value="currentItem"
            :step="0.1"
            warning
            :warning-rules="[
              {
                min_value: currentItem.water_level,
                message: `Berthing dolphin level is lower than the water level`
              },
              {
                max_value: currentItem.water_level + 10,
                message: `Berthing dolphin level larger than 10m above water level are relatively high`
              }
            ]"
            @update="store.update()"
          ></TextField>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col>
          <TextField
            v-if="store.fields['mooring_point_y']"
            :store="store"
            :field="store.fields['mooring_point_y']"
            :model-value="currentItem"
            :step="0.1"
            @update="store.update()"
          ></TextField>
        </v-col>
        <v-col>
          <TextField
            v-if="store.fields['berthing_point_y']"
            :store="store"
            :field="store.fields['berthing_point_y']"
            :model-value="currentItem"
            :step="0.1"
            @update="store.update()"
          ></TextField>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="6" class="pb-1">
          <TextField
            :store="store"
            :field="store.fields['maximum_number_of_lines']"
            :model-value="currentItem"
            @update="store.update()"
          ></TextField>
        </v-col>
        <v-col cols="6" class="pb-1">
          <TextField
            :store="store"
            :field="store.fields['min_line_length']"
            :model-value="currentItem"
            @update="store.update()"
          ></TextField>
        </v-col>
      </v-row>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item class="pb-0 pt-4" title="Platform size and location">
      <template #title>
        <span class="mr-3"> Platform size and location </span>
        <HelpTextTooltip
          text="Define the size and location of the (rectangular) jetty platform (Y-coordinate is lateral distance of the platform front behind the berthing line; width is distance from platform front to rear)"
          size="small"
        ></HelpTextTooltip>
      </template>
      <v-row class="pt-2 pb-0">
        <v-col class="pl-1 pr-1">
          <TextField
            v-if="store.fields['y_location_platform']"
            :store="store"
            :field="store.fields['y_location_platform']"
            :model-value="currentItem"
            :step="0.5"
            @update="store.update()"
          ></TextField>
        </v-col>
        <v-col class="pl-1 pr-2">
          <TextField
            v-if="store.fields['platform_width']"
            style="z-index: 1"
            :store="store"
            :field="store.fields['platform_width']"
            :model-value="currentItem"
            :step="0.5"
            warning
            :warning-rules="[
              {
                min_value: 10,
                message: 'This is a relatively small platform width.'
              }
            ]"
            @update="store.update()"
          ></TextField>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="pr-1">
              <TextField
                v-if="store.fields['x1']"
                :store="store"
                :field="store.fields['x1']"
                :model-value="currentItem"
                @update="store.update()"
              ></TextField>
            </v-col>
            <v-col class="pl-1">
              <TextField
                v-if="store.fields['x2']"
                :store="store"
                :field="store.fields['x2']"
                :model-value="currentItem"
                @update="store.update()"
              ></TextField>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
