import { defineStore } from 'pinia'
import AppBaseStore from './appBaseStore'
import { useCalculationStore } from './calculation'
import { useShipContourStore } from './ship_contour'
import { useWinchStore } from './winch'
import { useFairleadStore } from './fairlead'
import { storeToRefs } from 'pinia/dist/pinia'
import { useShipApiStore } from './shipApi'
import _ from 'lodash'

import API from '@/api/config/apiUrls'
import { useApi } from '@/api/config/useApi'
import { HIGH_WATER, LOW_WATER } from '@/components/calculation/calculationParameterTypes'

const { api } = useApi()

const baseUrl = API.CORE.SHIPS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useShipStore = defineStore({
  id: 'ship',
  state: () => ({
    ...baseStore.state,
    addLoading: false,
    shipMenu: false,
    expandedShipIds: [],
    selectedItems: []
  }),
  actions: {
    ...baseStore.actions,
    resetShipState() {
      const shipContourStore = useShipContourStore()
      const winchStore = useWinchStore()
      const fairleadStore = useFairleadStore()
      shipContourStore.items = []
      winchStore.items = []
      fairleadStore.items = []
      this.items = []
    },
    toggleSelectedItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item)
      } else {
        this.selectedItems.push(item)
      }
    },
    emptySelectedItems() {
      this.selectedItems = []
    },
    setHide(item) {
      const obj = this.items.find((obj) => obj.id === item.id)
      obj.hide = !obj.hide
    },
    async addShip() {
      const calculationStore = useCalculationStore()
      const shipContourStore = useShipContourStore()
      const winchStore = useWinchStore()
      const fairleadStore = useFairleadStore()
      const shipApiStore = useShipApiStore()
      const url = 'ships/ship_api/'
      this.addLoading = true
      try {
        const response = await api.service(url).create({
          ship: shipApiStore.getSelectedItems,
          calculation: calculationStore.getCalculationId
        })
        response.map((item) => {
          shipContourStore.addItems(item['shipcontour_set'])
          winchStore.addItems(item['winch_set'])
          fairleadStore.addItems(item['fairlead_set'])
          this.items.push(_.omit(item, ['shipcontour_set', 'fairlead_set', 'winch_set']))
          this.items.sort((a, b) => a.id - b.id).sort((a, b) => b.capacity - a.capacity)
          let number = 1
          for (const item of this.items) {
            item.number = number
            number++
          }
        })
        shipApiStore.selectedItems = []
        this.addLoading = false
      } catch (e) {
        console.log(e)
        this.addLoading = false
      }
    },
    removeItem(payload) {
      let index = this.items.findIndex((item) => payload.id === item.id)
      this.items.splice(index, 1)
      if (this.currentItem && payload.id === this.currentItem.id) {
        this.currentItem.id = null
      }
      let number = 1
      for (const item of this.items) {
        item.number = number
        number++
      }
    },
    setShowLines(item, bool) {
      item.show_lines = bool
    }
  },
  getters: {
    ...baseStore.getters,
    getItems: (state) => {
      const { currentItem: currentCalculation } = storeToRefs(useCalculationStore())
      state.items.forEach((item) => {
        if (currentCalculation.value.high_low_water === HIGH_WATER) {
          item.draught = item.ballasted_draught
        } else if (currentCalculation.value.high_low_water === LOW_WATER) {
          item.draught = item.laden_draught
        }
      })
      return state.items
    },
    fields(state) {
      const fields = state.options.actions?.POST
      if (!fields) {
        return null
      }
      return fields
    },
    getShowLinesItemsIds() {
      return this.getShowLinesItems.map((item) => item.id)
    },
    getShowLinesItems(state) {
      return state.items.filter((item) => item.show_lines)
    }
  }
})
