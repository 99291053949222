import { defineStore } from "pinia";
import { useShipStore } from "./ship";

import API from "@/api/config/apiUrls";
import AppBaseStore from "./appBaseStore";

import { useApi } from "@/api/config/useApi";

const { api } = useApi();

const baseUrl = API.CORE.WINCHES.INDEX;
const baseStore = new AppBaseStore(baseUrl, api);

export const useWinchStore = defineStore({
  id: "winch",
  state: () => ({
    ...baseStore.state,
  }),
  actions: {
    ...baseStore.actions,
  },
  getters: {
    ...baseStore.getters,
    showLinesItems(state) {
      const shipStore = useShipStore();
      return state.items
        ? state.items.filter((item) =>
            shipStore.getShowLinesItemsIds.includes(item.ship)
          )
        : [];
    },
    getItemOnFairlead: (state) => {
      return (fairlead) => {
        return state.items.find((winch) => winch.id === fairlead.winch);
      };
    },
  },
});
