<script setup>
import { storeToRefs } from "pinia/dist/pinia";
import { useShipApiStore } from "../../stores/shipApi";

const store = useShipApiStore();

const { selectedItems: selectedShips, items: shipItems } = storeToRefs(
  useShipApiStore()
);

function getShipName(id) {
  const ship = shipItems.value.find((item) => item.id === id);
  if (ship) {
    return ship;
  }
}
</script>
<template>
  <div>
    <v-chip v-for="item in selectedShips" size="small" class="ma-1">
      {{
        getShipName(item.id)?.type +
        " " +
        getShipName(item.id)?.capacity +
        " " +
        store.getCapacityUnit
      }}
      <v-icon
        class="ml-1"
        icon="mdi-close-circle"
        @click="store.toggleSelectedItem(item)"
      ></v-icon>
    </v-chip>
  </div>
</template>
