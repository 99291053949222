<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: false
  },
  actionText: {
    type: String,
    required: false,
    default: 'Delete'
  },
  bulkActionText: {
    type: String,
    required: false,
    default: 'Are you sure you want to delete these {amount} items?'
  },
  buttonColor: {
    type: String,
    required: false,
    default: 'red'
  },
  bulkAction: {
    type: Boolean,
    required: false,
    default: false
  },
  itemsToAction: {
    type: Array,
    required: false,
    default: () => []
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
  dialog: {
    type: Boolean,
    required: false,
    default: false
  }
})

const message = computed(() => {
  if (props.bulkAction) {
    return props.bulkActionText.replace('{amount}', props.itemsToAction.length)
  }
  return props.actionText + ' ' + props.name + '?'
})

const emit = defineEmits(['action', 'toggleDialog'])

const dialog = computed({
  get() {
    return props.dialog
  },
  set(value) {
    emit('toggleDialog')
  }
})
</script>

<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="emit('toggleDialog')">
    <v-card class="pa-3">
      <v-card-title>
        <span>{{ actionText }}</span>
      </v-card-title>
      <v-card-text class="pt-3 pl-4" v-html="message"></v-card-text>
      <slot name="info"></slot>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="emit('toggleDialog')"
          :disabled="loading"
          variant="text"
          class="text-none"
          color="primary"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          :color="buttonColor"
          variant="text"
          class="text-none"
          @click="emit('action')"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
