import { defineStore } from "pinia";
import AppBaseStore from "./appBaseStore";
import API from "@/api/config/apiUrls";

import { useApi } from "@/api/config/useApi";

const { api } = useApi();

const baseUrl = API.CORE.SHIP_CONTOURS.INDEX;
const baseStore = new AppBaseStore(baseUrl, api);

export const useShipContourStore = defineStore({
  id: "ship_contour",
  state: () => ({
    ...baseStore.state,
  }),
  actions: {
    ...baseStore.actions,
  },
  getters: {
    ...baseStore.getters,
  },
});
