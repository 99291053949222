import {
  FAIRLEAD_LABEL,
  HIDDEN_LABEL,
  PEDESTAL_LABEL,
  TAULINE_LABEL,
  WINCH_LABEL,
} from "./constantVariables";
import type { TooltipItem } from "chart.js";
import type { TickContext } from "./type/chartTypes";

const label = (context: TooltipItem<any>) => {
  // const label = context.dataset.label || "";
  // const item = context.dataset.data[context.dataIndex];
  // if (!label) {
  //   return null;
  // }
  //
  // if (label.includes(FAIRLEAD_LABEL)) {
  //   return (
  //     "F" +
  //     (item.index + 1) +
  //     ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)}, z: ${item.z.toFixed(
  //       2
  //     )})`
  //   );
  // } else if (label.includes(WINCH_LABEL)) {
  //   return (
  //     "W" +
  //     (item.index + 1) +
  //     ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)}, z: ${item.z.toFixed(
  //       2
  //     )})`
  //   );
  // } else if (label.includes(PEDESTAL_LABEL)) {
  //   return (
  //     "P" +
  //     (item.index + 1) +
  //     ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)}, z: ${item.z.toFixed(
  //       2
  //     )})`
  //   );
  // }
  return;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: label,
      },
      filter: function (context: TooltipItem<any>) {
        return (
          context.dataset.label !== "Ship Contour" &&
          context.dataset.label !== TAULINE_LABEL
        );
      },
    },
    zoom: {
      pan: { enabled: true, modifierKey: null },
      zoom: {
        wheel: {
          enabled: true,
          modifierKey: "ctrl",
        },
        pinch: {
          enabled: true,
        },
        mode: "xy",
      },
    },
    legend: {
      position: "top",
      align: "center",
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
        z: 1,
        showLabelBackdrop: true,
        minRotation: 0,
        maxRotation: 0,
        crossAlign: "near",
        color: function (context: TickContext) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value: number) {
          if (value === 0) {
            return [value];
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context: TickContext) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
    y: {
      position: "right",
      ticks: {
        display: true,
        mirror: true,
        padding: 30,
        showLabelBackdrop: true,
        z: 1,
        color: function (context: TickContext) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value: number) {
          if (value === 0) {
            return value;
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context: TickContext) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context: TickContext) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
  },
};
