import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import * as Sentry from '@sentry/vue'
import useEventsBus from '@/bus/eventBus'
import { SpeckleApp } from '@rhdhv/vue-speckle'
import apiUrls from '@/api/config/apiUrls.js'
import HeaderImage from '/header5.jpeg'
import HeaderImageLazy from '/header5_lazy.jpg'
import { AuthenticationManagement } from '@rhdhv/vue-authentication-middleware'
import { useDjangoAuthStore } from '@/stores/authDjango'
import { useApi } from '@/api/config/useApi'
import { UserManagementApp } from '@rhdhv/vue-user-management'
import { useAuthorizationStore } from '@/stores/authorization'

const pinia = createPinia()

const { api } = useApi()
pinia.use(({ store }) => {
  store.$api = api
  store.$apiUrls = apiUrls
})
const { bus } = useEventsBus()

const app = createApp(App).use(router).use(vuetify).use(pinia)

const mode = import.meta.env.MODE

if (mode !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://ccbad27a6c199b580ba708231d811a91@o4506110381391872.ingest.sentry.io/4506110411603968',
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracingOrigins: ['*'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in cloud.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const authStore = useDjangoAuthStore(pinia)

app
  .use(AuthenticationManagement, {
    appTitle: 'MOOR²',
    headerImage: HeaderImage,
    headerImageLazy: HeaderImageLazy,
    feathers: api,
    authStore: authStore,
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    showForgotPassword: false
  })
  .provide('useAuthStore', authStore)
  .provide('CustomLogInButton', null)

app.use(SpeckleApp, {
  VITE_APP_SPECKLE_ID: import.meta.env.VITE_APP_SPECKLE_ID,
  VITE_APP_SPECKLE_SECRET: import.meta.env.VITE_APP_SPECKLE_SECRET,
  VITE_APP_SERVER_URL: import.meta.env.VITE_APP_SERVER_URL,
  VITE_APP_SPECKLE_NAME: import.meta.env.VITE_APP_SPECKLE_NAME,
  bus: bus
})

app.use(UserManagementApp, {
  authorizationStore: useAuthorizationStore(),
  authStore: authStore
})

app.mount('#app')
export default app
