import {
  FAIRLEAD_LABEL,
  MOORING_POINT_LABEL,
  WINCH_LABEL,
} from "./chartLabels";

const label = (context) => {
  let sum = (0).let;
  let label = context.dataset.label || "";
  const item = context.dataset.data[context.dataIndex];
  if (!label) {
    return null;
  }
  if (label.includes(MOORING_POINT_LABEL)) {
    return (
      item.name +
      ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)}, z: ${item.z.toFixed(
        2
      )})`
    );
  } else if (label.includes(FAIRLEAD_LABEL)) {
    return (
      "F" +
      item.number +
      ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)}, z: ${item.z.toFixed(
        2
      )})`
    );
  } else if (label.includes(WINCH_LABEL)) {
    return (
      "W" + item.number + ` (x: ${item.x.toFixed(2)}, y: ${item.y.toFixed(2)})`
    );
  }
  return label;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: label,
      },
    },
    datalabels: {
      labels: {
        title: {
          color: null,
        },
      },
    },
    zoom: {
      pan: { enabled: true, modifierKey: null },
      zoom: {
        wheel: {
          enabled: true,
          modifierKey: "ctrl",
        },
        pinch: {
          enabled: true,
        },
        mode: "xy",
      },
    },
    legend: {
      onClick: null,

      labels: {
        filter: function (l) {
          if (l.text) {
            return l.text.includes("Capacity");
          } else {
            return null;
          }
        },
      },
      display: true,
      position: "chartArea",
      align: "end",
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
        z: 1,
        showLabelBackdrop: true,
        minRotation: 0,
        maxRotation: 0,
        crossAlign: "near",
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value, index, ticks) {
          if (value === 0) {
            return [value, "Spotting line"];
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
    y: {
      position: "right",
      ticks: {
        display: true,
        mirror: true,
        padding: 30,
        showLabelBackdrop: true,
        z: 1,
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value, index, ticks) {
          if (value === 0) {
            return "Berthing line         " + value;
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
  },
};
