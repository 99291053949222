<script setup>
import { useCalculationStore } from '@/stores/calculation'
import { useRoute } from 'vue-router/dist/vue-router'
import router from '@/router'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const route = useRoute()
const store = useCalculationStore()

const { selectedItems, fields } = storeToRefs(store)

const props = defineProps({
  order: { type: Number, required: true },
  item: { type: Object, required: true }
})

const berthType = computed(() => {
  return fields.value?.berth_type.choices.find((choice) => choice.value === props.item.berth_type)
    ?.display_name
})

const shipType = computed(() => {
  return fields.value?.ship_type.choices.find((choice) => choice.value === props.item.ship_type)
    ?.display_name
})

const mooringSide = computed(() => {
  return fields.value?.mooring_side.choices.find(
    (choice) => choice.value === props.item.mooring_side
  )?.display_name
})

function navigateToCalculation(id) {
  router.push({
    name: 'calculation',
    params: { id: route.params.id, calculationId: id }
  })
}

function toggleMenu(event) {
  const item = JSON.parse(JSON.stringify(props.item))
  store.toggleMenu({ x: event.clientX, y: event.clientY }, item)
}

const isSelected = computed({
  get: () => {
    return selectedItems.value?.includes(props.item) || false
  },
  set: () => {
    store.toggleSelectedItem(props.item)
  }
})

const emit = defineEmits(['duplicate', 'editItem', 'deleteItem'])
</script>
<template>
  <tr
    v-ripple
    style="cursor: pointer"
    @click="navigateToCalculation(item.id)"
    :class="{
      'selected-item': isSelected
    }"
  >
    <td>
      <v-checkbox
        color="primary"
        v-model="isSelected"
        density="compact"
        hide-details
        @click.stop=""
      ></v-checkbox>
    </td>
    <td class="text-center">{{ order }}</td>
    <td>
      {{ item.name }}
    </td>
    <td>
      {{ berthType }}
    </td>
    <td>
      {{ shipType }}
    </td>
    <td>
      {{ mooringSide }}
    </td>
    <td>
      {{ item.description }}
    </td>
    <td class="text-center">
      <v-btn
        icon="mdi-pencil"
        @click.stop="emit('editItem', item)"
        size="32"
        variant="text"
      ></v-btn>
    </td>
  </tr>
</template>
