import { defineStore } from 'pinia'
import AppBaseStore from './appBaseStore'
import { useCalculationStore } from './calculation'
import { useFairleadStore } from './fairlead'
import { useOptimisationStore } from './optimisation'
import { useShipStore } from './ship'
import { useWinchStore } from './winch'
import { useMooringPointStore } from './mooring_point'
import API from '@/api/config/apiUrls'
import { useApi } from '@/api/config/useApi'
import { useAuthorizationStore } from '@/stores/authorization'
import { useProjectUserStore } from '@/stores/project_user'
import { useDjangoAuthStore } from '@/stores/authDjango'

const { api } = useApi()

const baseUrl = API.CORE.PROJECTS.INDEX
const baseStore = new AppBaseStore(baseUrl, api)

export const useProjectStore = defineStore({
  id: 'projects',
  state: () => ({
    ...baseStore.state,
    servicePermissionProjects: [],
    filterFields: ['name', 'location', 'client', 'description'],
    drawer: true,
    speckleDrawer: false
  }),
  actions: {
    ...baseStore.actions,
    resetAll() {
      const fairleadStore = useFairleadStore()
      const optimisationStore = useOptimisationStore()
      const winchStore = useWinchStore()
      const shipStore = useShipStore()
      const calculationStore = useCalculationStore()
      const mooringPointStore = useMooringPointStore()
      fairleadStore.$reset()
      winchStore.$reset()
      shipStore.$reset()
      optimisationStore.$reset()
      calculationStore.$reset()
      mooringPointStore.$reset()
      this.$reset()
    },
    toggleDrawer(value = null) {
      if (value !== null) {
        this.drawer = value
      } else {
        this.drawer = !this.drawer
      }
    },
    toggleSpeckleDrawer(value = null) {
      if (value !== null) {
        this.speckleDrawer = value
      } else {
        this.speckleDrawer = !this.speckleDrawer
      }
    },
    async servicePermissionItems() {
      const projectUserStore = useProjectUserStore()
      const authStore = useDjangoAuthStore()
      const authorizationStore = useAuthorizationStore()

      const userId = authStore.user?.id
      await projectUserStore.fetchItems({ user: userId })

      const roleItems = projectUserStore.items.map((item) => {
        return {
          role: item.role,
          id: item.project
        }
      })
      this.servicePermissionProjects = await authorizationStore.getServicePermissionByRole({
        roleItems: roleItems,
        permission: ['change_project', 'delete_project']
      })
    }
  },
  getters: {
    ...baseStore.getters,
    getCurrentProject(state) {
      return state.currentItem ? state.currentItem : null
    }
  }
})
